import { useEffect } from 'react'
import Key from './Key'
import { ENTER_TEXT, DELETE_KEY_ICON } from '../../constants/strings'
import { DigitStatus } from '../../lib/statuses'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../../context/AppStoreContext'

type Props = {
    onChar: (value: string) => void
    onDelete: () => void
    onEnter: () => void
    isRevealing?: boolean
    digitStatuses: { [key: string]: DigitStatus },
}

const Keyboard = ({
    onChar,
    onDelete,
    onEnter,
    isRevealing,
    digitStatuses,
}: Props) => {
    const store = useAppStore()
    const isLeftyConfig = store.leftyConfiguration
    const keyRowClassName = "flex justify-center"

    const onClick = (value: string) => {
        if (value === 'ENTER') {
            onEnter()
        } else if (value === 'DELETE') {
            onDelete()
        } else {
            onChar(value)
        }
    }

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                onEnter()
            } else if (e.code === 'Backspace') {
                onDelete()
            } else {
                const key = e.key
                if (key.length === 1 && key >= '0' && key <= '9' && digitStatuses[key] !== 'absent') {
                    onChar(key)
                }
            }
        }
        window.addEventListener('keyup', listener)
        return () => {
            window.removeEventListener('keyup', listener)
        }
    }, [onEnter, onDelete, onChar, digitStatuses])

    const digits = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    if (isLeftyConfig)
        digits.unshift(0)
    else
        digits.push(0)

    const keyClassName = 'text-xl w-7 max-w-[50px] flex-grow font-bold'
    const guessKeyClassName = 'text-base max-w-[150px] w-10 font-bold text-white'
    const bsKeyClassName = 'text-3xl max-w-[150px]'

    return (
        <div className="flex flex-col">
            <div className={keyRowClassName}>
                <div className={`${keyRowClassName} mb-[6px] w-full`}>
                    {isLeftyConfig &&
                        <Key value="ENTER" onClick={onClick} keyClassName={guessKeyClassName}>
                            {ENTER_TEXT}
                        </Key>
                    }
                    {digits.slice(0, 5).map(key =>
                        <Key value={key.toString()}
                            key={key}
                            onClick={onClick}
                            status={digitStatuses[key]}
                            isRevealing={isRevealing}
                            keyClassName={keyClassName}
                        />
                    )}
                    {!isLeftyConfig &&
                        <Key value="ENTER" onClick={onClick} keyClassName={guessKeyClassName}>
                            {ENTER_TEXT}
                        </Key>
                    }
                </div>
            </div>
            <div className={keyRowClassName}>
                {isLeftyConfig &&
                    <Key value="DELETE" onClick={onClick} keyClassName={bsKeyClassName}>
                        {DELETE_KEY_ICON}
                    </Key>
                }
                {digits.slice(5, 10).map(key =>
                    <Key value={key.toString()}
                        key={key}
                        onClick={onClick}
                        status={digitStatuses[key]}
                        isRevealing={isRevealing}
                        keyClassName={keyClassName}
                    />
                )}
                {!isLeftyConfig &&
                    <Key value="DELETE" onClick={onClick} keyClassName={bsKeyClassName}>
                        {DELETE_KEY_ICON}
                    </Key>
                }
            </div>
        </div>
    )
}

export default observer(Keyboard)
