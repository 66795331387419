import { AppState } from '../state';
import { createContext, ReactNode, useContext } from 'react'

type Props = {
  children?: ReactNode
}

const StateContext = createContext<AppState | null>(null)

const AppStoreProvider = ({ children }: Props) => {
  const store = new AppState()
  return (
    <StateContext.Provider value={store}>{children}</StateContext.Provider>
  )
}

const useAppStore = () => {
  const store = useContext(StateContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

export { AppStoreProvider, useAppStore };
