import classNames from 'classnames';
import { uniqueId } from 'lodash-es'

type Props = {
  title: string,
  classes? : string
}

const MiniHeader = ({ title, classes = 'text-sm line-cell' } : Props) => {
    const cellClasses = classNames(
        'flex align-middle text-center justify-center w-full text-slate-400 pb-1 w-full no-text-select uppercase',
        classes)

  return (
    <div key={uniqueId()} className={cellClasses}>
        &nbsp;&nbsp;&nbsp;{title}&nbsp;&nbsp;&nbsp;
    </div>
  )
}

export default MiniHeader
