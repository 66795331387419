import { ArrowSmDownIcon, ArrowSmUpIcon, ArrowSmRightIcon, ArrowSmLeftIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { NumberStatus } from '../../lib/statuses'

export enum DirectionArrowType {
  NONE = 0,
  ANIMATED = 1,
  STATIC = 2
}

type Props = {
  animate: boolean
  proximity: NumberStatus
  isLeft?: boolean
}

export const DirectionArrow = ({ animate, proximity, isLeft = true }: Props) => {
  const isLower = proximity === NumberStatus.LOWER
  const isHigher = proximity === NumberStatus.HIGHER
  const isOther = !isLower && !isHigher

  const classes = classNames(
    'absolute mb-12 z-20 w-4 h-4 mt-12 text-black bg-unknown dark:bg-unknown-dark dark:text-white rounded-xl',
    {
        '-left-5' : isLeft,
        '-right-5' : !isLeft,
        '-bottom-[38px]': isHigher,
        '-top-[37px]' : isLower,
        'top-[2px] iPhonePro:top-[50px] smish:top-[67px]' : isOther,
        'bulge-left' : animate && isLeft && !isOther,
        'bulge-right' : animate && !isLeft && !isOther,
        'bulge-left-skill-stop' : animate && isLeft && isOther,
        'bulge-right-skill-stop' : animate && !isLeft && isOther
    }
  )
  return (
    <>
        {
            isLower ? <ArrowSmDownIcon className={classes} />
            : isHigher ? <ArrowSmUpIcon className={classes} />
            : isLeft ? <ArrowSmRightIcon className={classes} />
            : <ArrowSmLeftIcon className={classes} />
        }
    </>
  )
}
