import { findLastIndex, isEmpty, isNil, isUndefined } from 'lodash-es'
import { DigitStatus, getGuessStatusesFromArray } from './statuses'
import { MAX_DIGITS } from '../constants/settings'

const isEmptyArray = (guess: string[]): boolean => guess.every(guess => isEmpty(guess))

export const indexOfNextOpenCell = (guess: string[], numCells: number, currentIndex: number = 0): number => {
    var openCell = -1

    if (currentIndex >= 0 && currentIndex < numCells) {
        openCell = guess.findIndex((g, i) => isEmpty(g) && i >= currentIndex)
    }

    // Recurse once from the beginning if nothing open at end
    if (openCell === -1 && (isNil(currentIndex) || currentIndex > 0))
        return indexOfNextOpenCell(guess, numCells)

    return openCell
}

export const indexOfFirstNonLockedCell = (statuses: DigitStatus[]) : number =>
    statuses.findIndex((status => status !== DigitStatus.CORRECT))

export const areAllItemsUndefined = (arr: any[]) : boolean => arr.every(element => isUndefined(element))

export const indexOfPreviousNonLockedCell = (statuses: DigitStatus[], currentIndex: number) : number =>
    currentIndex === 0
        ? -1
        : findLastIndex(statuses.slice(0, currentIndex), status => status !==  DigitStatus.CORRECT)

export const eraseAtIndex = (charStatuses: DigitStatus[], guess: string[], i: number): string[] => {
    const workingGuess = [...guess]
    if (!isEmpty(workingGuess[i]) && charStatuses[i] !== DigitStatus.CORRECT) {
        workingGuess[i] = ''
    }
    return workingGuess
}

export const isEmptyOrUndefinedString = (str: String) => {
    return isUndefined(str) || isEmpty(str)
}

export const isGuessComplete = (currentGuess: string[]) => {
    return currentGuess.length > 0 && currentGuess.every(digit => !isEmptyOrUndefinedString(digit))
}

export const setProperCursorPosition = (
    currentGuess: string[],
    guesses: string[],
    solution: string,
    cursorPositionValue: number,
    cursorPositionFn: React.Dispatch<React.SetStateAction<number>>) => {

    // Ensure cursor starts at first open position (skipping locked cells)
    const isCurrentGuess = !isEmpty(currentGuess) && !areAllItemsUndefined(currentGuess)
    if (!isCurrentGuess && guesses.length === 0) return

    const guess = isCurrentGuess ? currentGuess : guesses[guesses.length - 1].split('')
    const lastDigitIndex = solution.length - 1

    if (isUndefined(cursorPositionValue) || cursorPositionValue <= 0
        || (cursorPositionValue === lastDigitIndex && guess[lastDigitIndex] !== '')) {
        if (!isEmptyArray(guess)) {
            const statuses = getGuessStatusesFromArray(guess, solution)
            const newCursorIndex = indexOfFirstNonLockedCell(statuses)
            if (newCursorIndex !== cursorPositionValue)
                cursorPositionFn(newCursorIndex)
        }
    } else if (isEmptyArray(guess) && cursorPositionValue !== 0)
        cursorPositionFn(0)
}

// Decoding algorithm, hopefully sufficient to not reverse-engineer when minified
// 1. Strip all hyphens
// 2. Transform last char of string to restore Base64 padding: '#' -> '=' and '&' -> '=='
// 2. Strip the first character
// 3. Starting with chars at index 1 and 2, strip out two chars, skip a char and strip out two chars until hitting '=' or end of string
// 4. For each base64-encoded byte (except padding):
//    a. Transform the byte through a pre-determined fixed array with an offset of the 1-based digit index multiplied by -13 for even digit indexes and -37 for odd digit indexes
// 5. Base64 decode
// 6. Reverse the first 4 digits
// 7. Strip leading 'x' chars (preserves leading zeros)
export const decodeSolution = (encodedSolution: string) => {
    if (isEmpty(encodedSolution)) return ''
    var encodedSolutionWithNoise = encodedSolution.replaceAll('-', '')
    if (encodedSolutionWithNoise.endsWith('&'))
        encodedSolutionWithNoise = encodedSolutionWithNoise.slice(0, -1).concat('==')
    else if (encodedSolutionWithNoise.endsWith('#'))
        encodedSolutionWithNoise = encodedSolutionWithNoise.slice(0, -1).concat('=')
    encodedSolutionWithNoise = encodedSolutionWithNoise.slice(1)

    var cleanScrambledEncoding = ''
    for (let i = 0; i < encodedSolutionWithNoise.length - 2; i = i + 3) {
        const encodedChar = encodedSolutionWithNoise.substring(i, i + 1)
        if (encodedChar === '=') {
            cleanScrambledEncoding += cleanScrambledEncoding.slice(encodedSolutionWithNoise.length - i)
            break
        }

        cleanScrambledEncoding += encodedSolutionWithNoise.substring(i, i + 1)
    }

    var cleanEncoding = ''
    const base64Chars   = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const obscuredChars = "jkDlEmnCFtuvwGHIJK789LBNopXabQRcUVWghiPqr23MA4sxYZyz01OSdefT56"
    for (let i = 0; i < cleanScrambledEncoding.length; i++) {
        var offsetIndex = (i % 2 === 0 ? 13 : 37) * (i + 1)
        // Can't simply subtract the offset index because it will end up negative-- instead add many lengths of string minus the offset so the mod operator will have a positive value
        cleanEncoding += base64Chars[(obscuredChars.indexOf(cleanScrambledEncoding[i]) + (base64Chars.length * (i + 1)) - offsetIndex) % base64Chars.length]
    }

    var solution = window.atob(cleanEncoding)
    solution = solution[3] + solution[2] + solution[1] + solution[0] + solution.slice(4 - MAX_DIGITS)

    return solution.replace(/^x+/, '')
}

// Returns 7 days in the future-- this is the sweepstakes entry date for AMOE or Wumber submission
export const sweepstakesDate = () : string => {
    const date = new Date(new Date().setHours(0, 0, 0, 0))
    date.setDate(date.getDate() + 7)
    return date.toLocaleDateString("en-US")
}

export const sweepstakesActivationDate = () : string => {
    const date = new Date(new Date().setHours(0, 0, 0, 0))
    date.setDate(date.getDate() + 6)
    return date.toLocaleDateString("en-US")
}
