import BaseModal from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const classes = "text-xs text-gray-500 dark:text-gray-400 mt-0 mb-3 text-left no-text-select"
const sectionClasses = "text-sm text-left text-black dark:text-white no-text-select"

export const TermsModal = ({ isOpen, handleClose }: Props) => {
    return (
        <BaseModal title="Terms of Use" isOpen={isOpen} handleClose={handleClose}>
            <div className="container">
                <div className={sectionClasses}>
                    Last Revised
                </div>
                <p className={classes}>
                    1/1/2024
                </p>
                <p className={classes}>Please read these Terms of Use carefully before using our Service.</p>
                <div className={sectionClasses}>
                    Interpretation
                </div>
                <p className={classes}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <div className={sectionClasses}>
                    Definitions
                </div>
                <p className={classes}>For the purposes of these Terms of Use:</p>
                <ul className={classes}>
                    <li className="mb-1">
                        <p><u className={classes}>Affiliate</u> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Company</u> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in these Terms of Use) refers to Wumber LLC, P.O Box 740892, Boynton Beach, FL 33474.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Device</u> means any device that can access the Service such as a computer, a smartphone, tablet, or other mobile device.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Jurisdiction</u> refers to: Palm Beach County, Florida, United States</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Promotions</u> refer to contests, sweepstakes or other promotions offered through the Service.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Service</u> refers to Wumber and wumber.app.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Terms of Use</u> (also referred as &quot;Terms&quot;) mean these Terms of Use that form the entire agreement between You and the Company regarding Your use of the Service.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Third-party Social Media Service</u> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>Website</u> refers to Wumber, accessible from https://wumber.app.</p>
                    </li>
                    <li className="mb-1">
                        <p><u className={classes}>You</u> (referred to as either &quot;You&quot; or &quot;Your&quot; in these Terms of Use) means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                </ul>
                <p className={classes} />

                <div className={sectionClasses}>
                    Acknowledgment
                </div>
                <p className={classes}>These are the Terms of Use governing the use of this Service and the agreement that operates between You and the Company. These Terms set out the rights and obligations of all users regarding the use of the Service.</p>
                <p className={classes}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
                <p className={classes}>By accessing or using the Service You agree to be bound by these Terms. If You disagree with any part of these Terms then You may not access the Service.</p>
                <p className={classes}>You represent that you are over the age of 13. The Company does not permit those under 13 to use the Service at any time. Furthermore, if you log in via a third-party service for purposes of Creating your own Wumber, you represent that you are over the age of 18.</p>
                <p className={classes}>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with Our Privacy Policy. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Service and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

                <div className={sectionClasses}>
                    Promotions
                </div>
                <p className={classes}>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>
                <p className={classes}>If You participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
                <p className={classes}>If You win a prize in any Promotions, You agree We have the right to use Your name, nickname, avatar, photograph, and/or hometown and state for promotional purposes in any and all media, without payment or consideration beyond the awarded Prize.</p>

                <div className={sectionClasses}>
                    Scripting Prohibition
                </div>
                <p className={classes}>You agree to use The Service in a direct interactive manner and not to automate or script actions on The Service.  Attempts to do so, whether for nefarious purposes or just fun, will result in You being banned from using the Service.  Additionally, if we have a suspicion You have not used the Service in a direct interactive manner, any Promotion payments may be delayed until a full investigation is complete and will be canceled if We determine your use of the Service was not wholly interactive.</p>
       
                <div className={sectionClasses}>
                    Links to Other Websites
                </div>
                <p className={classes}>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                <p className={classes}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                <p className={classes}>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>

                <div className={sectionClasses}>
                    Termination
                </div>
                <p className={classes}>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms.</p>
                <p className={classes}>Upon termination, Your right to use the Service will cease immediately.</p>

                <div className={sectionClasses}>
                    Limitation of Liability
                </div>
                <p className={classes}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of these Terms and Your exclusive remedy for all of the foregoing shall be limited 100 USD since the Service is completely free.</p>
                <p className={classes}>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                <p className={classes}>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

                <div className={sectionClasses}>
                    &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
                </div>
                <p className={classes}>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                <p className={classes}>Without limiting the foregoing, neither the Company nor any of the company's providers make any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                <p className={classes}>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>

                <div className={sectionClasses}>
                    Governing Law
                </div>
                <p className={classes}>The laws of the Jurisdiction, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Service may also be subject to other local, state, national, or international laws.</p>

                <div className={sectionClasses}>
                    Disputes Resolution
                </div>
                <p className={classes}>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>

                <div className={sectionClasses}>
                    For European Union (EU) Users
                </div>
                <p className={classes}>If You are a European Union resident, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>

                <div className={sectionClasses}>
                    United States Legal Compliance
                </div>
                <p className={classes}>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>

                <div className={sectionClasses}>
                    Severability
                </div>
                <p className={classes}>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

                <div className={sectionClasses}>
                    Waiver
                </div>
                <p className={classes}>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>

                <div className={sectionClasses}>
                    Changes to these Terms
                </div>
                <p className={classes}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide notice prior to your next use of the Service when any new terms would take effect. What constitutes a material change will be determined at Our sole discretion.</p>
                <p className={classes}>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                <p className={classes}>The last revision date is shown at the top of the currently displayed Terms of Use. You can check for updates to our Terms by noting the "Last Revised" date on our Terms of Use whenever you access our Service.</p>

                <div className={sectionClasses}>
                    Contact Us
                </div>
                <p className={classes}>If you have any questions about these Terms of Use, You can email us at <a tabIndex={-1} className={`${sectionClasses} text-xs`} href="mailto:info@wumber.app"><u>info@wumber.app</u></a>.</p>
            </div>
        </BaseModal>
    )
}
