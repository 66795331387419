import { partition, orderBy, uniqueId } from 'lodash-es'
import { MAX_GUESSES } from '../../constants/settings'
import { getGuessStatuses, NumberStatus } from '../../lib/statuses'
import FirstRow from './FirstRow'
import CompletedRow from './CompletedRow'
import CurrentRow from './CurrentRow'
import { DirectionArrowType } from './DirectionArrow'
import EmptyRow from './EmptyRow'
import { getProximity } from '../../lib/statuses'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../../context/AppStoreContext'
import { Animated } from "react-animated-css"
import classNames from 'classnames';

type Props = {
    currentGuess: string[]
    cursorIndex: number
    isRevealing?: boolean
    isNewRow?: boolean
    currentRowClass: string
    hasWon: boolean
    setCurrentGuess: (guess: string[]) => void
    setCursorIndex: (index: number) => void
    setCurrentRowClass: (className: string) => void
    onEnter: () => void,
    showFullBoard: boolean
}

const Board = ({
    onEnter,
    setCurrentGuess,
    setCursorIndex,
    cursorIndex,
    currentGuess,
    isRevealing,
    isNewRow,
    currentRowClass,
    setCurrentRowClass,
    hasWon,
    showFullBoard
}: Props) => {
    const store = useAppStore()
    const guesses = store.guesses
    const solution = store.solution
    const prefillDigits = store.prefillCorrectDigits

    const hasLost = !hasWon && (guesses.length >= MAX_GUESSES)
    const isGameOver = hasWon || hasLost
    const numEmpties = isGameOver ? MAX_GUESSES - guesses.length : MAX_GUESSES - guesses.length - 1
    const empties = numEmpties > 0 ? Array.from(Array(numEmpties)) : []
    const numDigits = solution.length
    const mostRecentGuess = guesses.length === 0 ? '' : guesses[guesses.length - 1]
    const atGameStart = guesses.length === 0 && (currentGuess.length === 0 || currentGuess.join('').length === 0)
   
    // Prior statuses are always cumulative, so no need to check other rows
    const statuses = getGuessStatuses(prefillDigits ? mostRecentGuess : '', solution)

    let sortedGuesses = orderBy(guesses, Number, ['desc'])
    let [higherGuesses, lowerGuesses] = partition(sortedGuesses, g => Number(g) > Number(solution))
    let nextHighestGuessIndex = higherGuesses.length - 1
    let nextHighestGuess = higherGuesses.length === 0 ? "" : higherGuesses[higherGuesses.length - 1]
    let priorNextHighestGuess = higherGuesses.length <= 1 ? "" : higherGuesses[higherGuesses.length - 2]
    let nextLowestGuess = lowerGuesses.length === 0 ? "" : lowerGuesses[0]
    let priorNextLowestGuess = lowerGuesses.length <= 1 ? "" : lowerGuesses[1]
    let endGameLowerArrowIndex = mostRecentGuess === higherGuesses[nextHighestGuessIndex]
                                    ? nextHighestGuessIndex - 1 : nextHighestGuessIndex
    let wasLastGuessTooHigh = nextHighestGuess === mostRecentGuess
    let wasLastGuessTooLow = nextLowestGuess === mostRecentGuess

    // Reset automatic stats display one-time flag if game still in progress
    if (!isGameOver) store.statsShareDisplayed = false

    const gamePlayClasses = classNames(
        'flex flex-col max-w-[600px] gap-1 scale-125',
        {
            'iPhonePro:scale-[135%]' : numDigits === 6,    // Shrink a bit to make room for higher/lower arrows
            'iPhonePro:scale-150' : numDigits < 6
        },
        'iPhonePro:pt-12 md:pt-16 smish:scale-150 smish:pt-16 sm:pt-16 sm:scale-150 xs:scale-125'
    )

    const gameOverClasses = classNames(
        'flex flex-col max-w-[600px] pb-2',
        {
            'iPhonePro:scale-[135%]' : numDigits === 6,    // Shrink a bit to make room for higher/lower arrows
            'iPhonePro:scale-150' : numDigits < 6
        },
        'smish:scale-150 sm:scale-150 scale-125 mt-1'
    )
    
    const gameOverFullBoardClasses = classNames(
        'flex flex-col max-w-[600px] gap-1',
        {
            'iPhonePro:scale-[135%]' : numDigits === 6,    // Shrink a bit to make room for higher/lower arrows
            'iPhonePro:scale-150' : numDigits < 6
        },
        'iPhonePro:pt-14 iPhonePro:pb-10 smish:scale-150 sm:scale-150 scale-125 pt-10 mt-2'
    )

//console.log("Board rendering: isNewRow="+isNewRow+" isRevealing="+isRevealing+" newRowAnimationFinished="+store.newRowAnimationFinished)
    
    return (
        <>
            {/* Game in progress */}
            {!isGameOver &&
                <div key={"boardKey"} className={gamePlayClasses}>
                    {guesses.length === 0 &&
                        <>
                            <EmptyRow key={uniqueId()} numCells={numDigits} />
                            <FirstRow onEnter={onEnter} numSolutionDigits={numDigits} setCurrentGuess={setCurrentGuess} className={currentRowClass} />
                            <EmptyRow key={uniqueId()} numCells={numDigits} />
                        </>
                    }
                    {/* Next highest guess */}
                    {higherGuesses.length > 0 &&
                        <div key={uniqueId()}>
                            {/* Fade out next-highest guess */}
                            { priorNextHighestGuess !== "" && isNewRow && wasLastGuessTooHigh && !store.newRowAnimationFinished &&
                                <Animated className='z-50 absolute left-0' key={uniqueId()} animateOnMount animationIn='fadeOutUp' animationOut='fadeOut' isVisible={true}>
                                    <CompletedRow key={uniqueId()} guess={priorNextHighestGuess} proximity={NumberStatus.LOWER}
                                        isWaiting={false} isRevealing={false}
                                        directionArrowType={DirectionArrowType.STATIC}
                                    />
                                </Animated>
                            }
                            {/* Empty row above as guess moves up */}
                            { priorNextHighestGuess === "" &&
                                <div className='absolute left-0'>
                                    <EmptyRow key={uniqueId()} numCells={numDigits} />
                                </div>
                            }
                            { isNewRow && !store.newRowAnimationFinished && wasLastGuessTooHigh &&
                                <Animated className='z-50' key={uniqueId()} animateOnMount animationIn='slideInUp' animationOut='fadeOut' isVisible={true}>
                                    <CompletedRow key={uniqueId()} guess={nextHighestGuess} proximity={NumberStatus.LOWER}
                                        isWaiting={true} isRevealing={isRevealing}
                                        directionArrowType={DirectionArrowType.ANIMATED}
                                    />
                                </Animated>
                            }
                            {/* Immediately higher guess*/}
                            { (!isNewRow || store.newRowAnimationFinished || nextHighestGuess !== mostRecentGuess) && 
                                <CompletedRow key={uniqueId()} guess={nextHighestGuess} proximity={NumberStatus.LOWER}
                                    isWaiting={wasLastGuessTooHigh && !store.newRowAnimationFinished} isRevealing={isRevealing && nextHighestGuess === mostRecentGuess}
                                    directionArrowType={(isNewRow && wasLastGuessTooHigh) ? DirectionArrowType.ANIMATED : DirectionArrowType.STATIC}
                                />
                            }
                        </div>
                    }
                    {/* Empty row if no higher guesses */}
                    {guesses.length > 0 && guesses.length < MAX_GUESSES && higherGuesses.length === 0 &&
                        <div key={uniqueId()}>
                            <EmptyRow key={uniqueId()} numCells={numDigits} invisible={atGameStart} showStartHint={atGameStart} />
                        </div>
                    }
                    {/* Current row being filled */}
                    {guesses.length > 0 && guesses.length < MAX_GUESSES &&
                        <CurrentRow key={uniqueId()} guess={currentGuess} setCurrentGuess={setCurrentGuess} setCursorIndex={setCursorIndex}
                            cursorIndex={cursorIndex} className={currentRowClass} priorGuess={mostRecentGuess} statuses={statuses} shouldShowHint={!!isNewRow}
                            numGuesses={guesses.length} isWaiting={!store.newRowAnimationFinished} setCurrentRowClass={setCurrentRowClass}
                            /* TODO: want isWaiting condition to be !store.animatedHintFinished but this causes a loop */
                        />
                    }
                    {/* Next lowest guess */}
                    {lowerGuesses.length > 0 &&
                        <div key={uniqueId()}>
                            {/* Fade out next-lowest guess */}
                            { priorNextLowestGuess !== "" && isNewRow && wasLastGuessTooLow && !store.newRowAnimationFinished &&
                                <Animated className='z-50 absolute left-0' key={uniqueId()} animateOnMount animationIn='fadeOutDown' animationInDuration={750} animationOut='fadeOut' isVisible={true}>
                                    <CompletedRow key={uniqueId()} guess={priorNextLowestGuess} proximity={NumberStatus.HIGHER}
                                        isWaiting={false} isRevealing={false}
                                        directionArrowType={DirectionArrowType.STATIC}
                                    />
                                </Animated>
                            }
                            {/* Empty row below as guess moves down */}
                            { priorNextLowestGuess === "" &&
                                <div className='absolute left-0'>
                                    <EmptyRow key={uniqueId()} numCells={numDigits} />
                                </div>
                            }
                            { isNewRow && !store.newRowAnimationFinished && wasLastGuessTooLow &&
                                <Animated key={uniqueId()} animateOnMount animationIn='slideInDown' animationOut='fadeOut' isVisible={true}>
                                    <CompletedRow key={uniqueId()} guess={nextLowestGuess} proximity={NumberStatus.HIGHER}
                                        isWaiting={true} isRevealing={isRevealing}
                                        directionArrowType={DirectionArrowType.ANIMATED}
                                    />
                                </Animated>
                            }
                            { (!isNewRow || store.newRowAnimationFinished || nextLowestGuess !== mostRecentGuess) && 
                                <CompletedRow key={uniqueId()} guess={nextLowestGuess} proximity={NumberStatus.HIGHER}
                                    isWaiting={wasLastGuessTooLow && !store.newRowAnimationFinished}
                                    isRevealing={isRevealing && wasLastGuessTooLow && store.newRowAnimationFinished} 
                                    directionArrowType={(isNewRow && wasLastGuessTooLow) ? DirectionArrowType.ANIMATED : DirectionArrowType.STATIC}
                                />
                            }
                        </div>
                    }
                    {/* Empty row if no lower guesses */}
                    {guesses.length > 0 && guesses.length < MAX_GUESSES && lowerGuesses.length === 0 &&
                        <div key={uniqueId()}>
                            <EmptyRow key={uniqueId()} numCells={numDigits} invisible={atGameStart} showStartHint={atGameStart} />
                        </div>
                    }
                </div>
            }
            {/* Game over - default view of just solution row */}
            {isGameOver && !showFullBoard &&
                <div className={gameOverClasses}>
                    <CompletedRow key={uniqueId()} guess={mostRecentGuess} isRevealing={isRevealing} proximity={hasWon ? NumberStatus.CORRECT : NumberStatus.WRONG} showSolution standAlone />
                </div>
            }
            {/* Game over - full board view: color last guess wrong if a loss */}
            {isGameOver && showFullBoard &&
                <div className={gameOverFullBoardClasses}>
                    {/* Display the board in sorted order */}
                    {sortedGuesses.map((guess, i) =>
                        <CompletedRow key={uniqueId()} guess={guess} isRevealing={false}
                            proximity={hasLost && guess === mostRecentGuess ? NumberStatus.WRONG : getProximity(guess, solution)}
                            directionArrowType={i === endGameLowerArrowIndex || i === endGameLowerArrowIndex + 2
                                 ? DirectionArrowType.STATIC : DirectionArrowType.NONE }
                        />
                    )}
                    {empties.map(() => (
                        <EmptyRow key={uniqueId()} numCells={numDigits} />
                    ))}
                    <div className='mb-8' />
                </div>
            }
        </>
    )
}

export default observer(Board)
