import BaseModal from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const classes = "text-xs text-gray-500 dark:text-gray-400 mt-0 mb-2 text-left no-text-select"
const sectionClasses = "text-left text-sm dark:text-white no-text-select"

export const PrivacyModal = ({ isOpen, handleClose }: Props) => {
    return (
        <BaseModal title="Privacy Policy" isOpen={isOpen} handleClose={handleClose}>
            <div className="container">
                <div className={sectionClasses}>
                    Last Revised
                </div>
                <p className={classes}>
                    1/1/2024
                </p>

                <div className={sectionClasses}>
                    General
                </div>
                <p className={classes}>
                    This Privacy Policy describes the types of personal information that we collect, how we use it, and how and when it may be shared.
                    It also describes the rights and choices you have with respect to your information, including how you can make requests or inquire about your information.
                    If you do not agree with our Privacy Policy, please do not play Wumber, either online or as an App.
                </p>
                <p className={classes}>
                    The terms "Wumber", "we", "us", and "our" refer to the operator of wumber.app, the publisher of the Wumber iOS and Android apps, and any directly affiliated entities. The terms "third party" and "third parties" refer to any person or entity other than you and us.
                </p>
                <p className={classes}>
                    This Privacy Policy may be updated from time-to-time. The last revision date is shown at the top of the currently displayed Privacy Policy.
                    Please check for updates to our Privacy Policy by noting the "Last Revised" date on our Privacy Policy whenever you access our websites and mobile applications.
                </p>

                <div className={sectionClasses}>
                    Information We Collect
                </div>
                <p className={classes}>
                    If you don't login through a third-party service, such as Google, Facebook, or Twitter, we do not collect any information about you.
                    In order to create your own Wumber, you must login through a third-party service, from which you'll need to share your user name and city of residence.
                </p>
                <p className={classes}>
                    If you are the Winner in one of our Trivia Sweepstakes, you'll also need to provide a valid U.S. Phone number if you didn't provide one to the third-party service you're using to login to Wumber with.
                    This phone number will be used to pay any cash prizes you win via Venmo, CashApp, or PayPal, up to $600 in a calendar year.
                    Winnings over $600 will be sent via check, for which you'll need to provide your physical address.
                </p>

                <div className={sectionClasses}>
                    How We Use the Information We Collect
                </div>
                <p className={classes}>
                    Your third party logins are used by us to store and retrieve your gameplay data, create your own Wumber, and to identify you if/when you win a daily cash prize.
                    Third parties providing login mechanisms, may also use your login for Advertising purposes, as explained near the end of this Privacy Policy.
                </p>
                <p className={classes}>
                    Your phone number, email address, and/or physical address is used for prize payment and related purposes and, for annual winnings over $600, to issue the US Government required IRS 1099-MISC form.
                </p>
                <p className={classes}>
                    Our <i>Terms of Use</i> require that you consent to your name or username being displayed on wumber.app, in our iOS and Android apps, and on our Twitter feed if/when your then-current Wumber is randomly selected for a daily cash prize.
                </p>
                <p className={classes}>
                    We will not share your information with third parties, except when responding to lawful governmental requests and complying with any applicable legal and regulatory obligations.
                </p>

                <div className={sectionClasses}>
                    Retention of Your Information
                </div>
                <p className={classes}>
                    We store your information as needed to accomplish the purposes identified in this Privacy Policy and to meet legal requirements, including record retention and to resolve any disputes.
                    Our retention of your information is governed by applicable law and may extend beyond the time you cease to play Wumber.
                </p>
                <p className={classes}>
                    The personal information you provide is stored and processed on servers based in the United States. We take appropriate measures and safeguards to protect your information in accordance with applicable data protection laws.
                </p>

                <div className={sectionClasses}>
                    Use of Cookies
                </div>
                <p className={classes}>
                    This website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web server, either for only the duration of your visit ("session cookie") or for a fixed period ("persistent cookie"). Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                </p>

                <div className={sectionClasses}>
                    Children's Privacy
                </div>
                <p className={classes}>
                    While children 13 and older are allowed to play Wumber per our Terms of Use, logging in via a third-party service for purposes of creating your own Wumber is not allowed for anyone under the age of 18.
                    If you have reason to believe we may have collected information about your child, please contact us at <a tabIndex={-1} className={`${sectionClasses} text-xs`} href="mailto:info@wumber.app"><u>info@wumber.app</u></a>.
                </p>

                <div className={sectionClasses}>
                    Advertising
                </div>
                <p className={classes}>
                    We use Google AdSense to serve a single ad at the end of each game.  Third party vendors, including Google, use cookies to serve personalized ads.
                    Google's use of advertising cookies enables it and its partners to serve ads to you based on prior visits to wumber.app and any other Internet websites and/or connected apps.
                    You may opt out of Google's personalized advertising by visiting <a tabIndex={-1} className={`${sectionClasses} text-xs`} href="https://www.google.com/settings/ads"><u>Ads Settings</u></a>.
                </p>

                <div className={sectionClasses}>
                    Requests for Information
                </div>
                <p className={classes}>
                    You, or your authorized representative, may make requests for, or inquire about, your personal information by emailing us at <a tabIndex={-1} className={`${sectionClasses} text-xs`}  href="mailto:info@wumber.app"><u>info@wumber.app</u></a>.
                    You will need to provide strict proof of identity, and, for representatives, strict proof of authorization, before we will release any information.
                </p>
            </div>
        </BaseModal>
    )
}
