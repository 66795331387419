import classnames from 'classnames'
import { sweepstakesDate } from '../../../lib/utils'

type Props = {
    hasWon: boolean
}

const StatusPage = ({hasWon}: Props) => {
    const classes = classnames("mb-4 leading-5 text-lg")

    return (
        <div>
            <p className={classes}>
                Create your own Wumber to enter the {sweepstakesDate()} Wumber Trivia Sweepstakes<sup>*</sup>
            </p>
            {hasWon &&
                <>
                    <p className={classes}>
                        All you need is a unique number and associated trivia to enter.
                    </p>
                    <p className={classes}>
                       {/* The winner receives a free one-year subscription to the Times, an awesome T-shirt featuring your Wumber, and a cash prize of $1000!<sup>**</sup> */}
                       COMING SOON!
                    </p>
                </>
            }
            {!hasWon &&
                <p className={classes}>
                    Before you can create your own Wumber, you have to correctly guess Today's Wumber.
                    Then come back here with your unique number and associated trivia to create your Wumber.
                </p>
            }
            <p className="pt-2 mb-2 leading-4 text-sm">
                <sup>* NO PURCHASE NECESSARY. Alternate method of entry available. See our <i>Terms of Use</i> for full sweepstakes details.</sup>
            </p>
            <p className="mb-2 leading-4 text-sm">
                <sup>** Winner is responsible for any personal income tax liability.</sup>
            </p>
        </div>
    )
}

export default StatusPage
