import BaseModal from './BaseModal'
import GoogleButton from '../misc/GoogleSignin';
import FacebookButton from '../misc/FacebookSignIn';
import { useAppStore } from '../../context/AppStoreContext';
import { baseUrl } from '../../constants/settings';
import { runInAction } from 'mobx'
import TwitterButton from '../misc/TwitterSignIn';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

let store: any;

export const LoginModal = ({ isOpen, handleClose }: Props) => {
  store = useAppStore()

  const facebookSuccessResponse = (response: any) => {
    console.log("Facebook Login Success")
    console.log(response)
  
    response.authResponse.wumberUserId = store.playerId;
  
    const tokenBlob = new Blob([JSON.stringify(response.authResponse, null, 2)], { type: 'application/json' })
    const options: RequestInit = {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default'
    }
    fetch(`${baseUrl}/auth/facebook`, options)
      .then(r => {
        r.json().then(user => {
          runInAction(() => {
            store.token = user.token
            store.userName = user.userName
            store.playerId = user.userId
          });
          handleClose()
        })
      })
      .catch(error => {
        console.error('ERROR', error)
        return Promise.reject(error);
    })
  
  }

  const facebookFailureResponse = (response: any) => {
    console.log("Facebook Login Failure")
    console.log(response)
  };

  const googleResponse = (response: any) => {

    const tokenBlob = new Blob([JSON.stringify({
        tokenId: response.credential,
        userId: store.playerId
      }, null, 2)], { type: 'application/json' })
      const options: RequestInit = {
        method: 'POST',
        body: tokenBlob,
        mode: 'cors',
        cache: 'default'
      }
      fetch(`${baseUrl}/auth/google`, options)
        .then(r => {
          r.json().then(user => {
            runInAction(() => {
              store.token = user.token
              store.userName = user.userName
              store.playerId = user.userId
            });
            handleClose()
          })
        })
        .catch(error => {
          console.error('ERROR', error)
          return Promise.reject(error);
      })
  }

  return (
    <BaseModal title="Login" isOpen={isOpen} handleClose={handleClose}>
      <div className="container flex flex-col space-y-2 items-center" >
        <GoogleButton handleGoogleResponse={googleResponse}></GoogleButton>

        <FacebookButton
          handleFacebookSuccessResponse={facebookSuccessResponse}
          handleFacebookFailureResponse={facebookFailureResponse}
        ></FacebookButton>

        <TwitterButton></TwitterButton>
      </div>
    </BaseModal>
  )
}
