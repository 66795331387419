import { useContext } from 'react'
import { isEmpty } from 'lodash-es'
import classnames from 'classnames'
import PuzzleContext from '../../context/PuzzleContext.js'
import { SHOW_BOARD_TEXT, HIDE_BOARD_TEXT } from '../../constants/strings'
import { LEARN_MORE_TEXT, SHOW_STATS_TEXT } from '../../constants/strings'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { useAppStore } from '../../context/AppStoreContext'
import classNames from 'classnames'

type Props = {
    hasLost: boolean
    showFullBoard: boolean
    setShowFullBoard: React.Dispatch<React.SetStateAction<boolean>>
    setIsStatsModalOpen: (value: boolean) => void
}

const EndGameButtons = ({hasLost, showFullBoard, setShowFullBoard, setIsStatsModalOpen}: Props) => {
  const puzzle = useContext(PuzzleContext)
  const isMoreToLearn = !isEmpty(puzzle.VerifyUrl)
  const learnMoreLink = () => {
      window.open(puzzle.VerifyUrl, '_blank')
  }

  const store = useAppStore()
  const isHighContrast = store.highContrast

  const classes = classNames(
    'flex align-middle justify-center w-full mt-4 z-20',
    {
      'iPhonePro:mt-[34px]': !showFullBoard,
      'iPhonePro:mt-4': showFullBoard,
    }
  )

  const learn = classnames("mt-2 w-full rounded-sm border border-transparent px-4 py-2 text-base font-medium text-white",
  "shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 w-3/4 no-text-select",
  {
      "bg-error": !isHighContrast && hasLost,
      "bg-error-hc": isHighContrast && hasLost,
      "bg-correct dark:bg-correct-dark": !isHighContrast && !hasLost,
      "bg-correct-hc": isHighContrast && !hasLost
  })

  return (
    <>
      <div className={classes}>
        <div className='flex gap-4 w-full'>
          <button className='btn w-1/2 border-0 no-text-select' type="button" onClick={() => setShowFullBoard(!showFullBoard)}>
            {showFullBoard ? HIDE_BOARD_TEXT : SHOW_BOARD_TEXT}
          </button>
          <button className='btn w-1/2 no-text-select' type="button" onClick={() => {setIsStatsModalOpen(true)}}>
            {SHOW_STATS_TEXT}
          </button>
        </div>
      </div>
      <div className="flex justify-center w-full z-20 mt-1">
        <button autoFocus className={learn} type="button" disabled={!isMoreToLearn} onClick={() => learnMoreLink()}>
          <span className='relative pr-1 no-text-select'>{LEARN_MORE_TEXT}</span>
          <ExternalLinkIcon className='inline w-4 h-4 -mt-1' />
        </button>
      </div>
    </>
  )
}

export default EndGameButtons
