import classnames from 'classnames'

const EndSubmitPage = () => {
    const classes = classnames("text-sm text-gray-700 dark:text-gray-300 mb-2")

    return (
        <>
            <div className={classes}>
                Your Wumber has been created. Check back within 72 hours to ensure it's verified.
            </div>
            <div className={classes}>
                Your Wumber is automatically activated on the day it's verified.
                On subsequent days, your Wumber is only active in the midnight random selection pool if you correctly guess that day's Wumber.
            </div>
            <div className={classes}>
                Once verified, your Wumber is yours forever, so long as you correctly guess a Wumber at least once every 7 days.
            </div>
        </>
    )
}

export default EndSubmitPage