import './App.css'
import { isEmpty, invoke } from 'lodash-es'
import { useState, useEffect, useCallback } from 'react'
import Countdown from 'react-countdown'
import Confetti from 'react-confetti'
import { observer } from 'mobx-react'
import { Animated } from "react-animated-css";
import { runInAction } from 'mobx'
import Board from './components/board/Board'
import TriviaHints from './components/trivia/TriviaHints'
import TriviaAnswer from './components/trivia/TriviaAnswer'
import Keyboard from './components/keyboard/Keyboard'
import StatsModal from './components/modals/StatsModal'
import SettingsModal from './components/modals/SettingsModal'
import EndGameButtons from './components/misc/EndGameButtons'
import { GAME_COPIED_MESSAGE, NEW_PUZZLE_TEXT } from './constants/strings'
import PuzzleContext from './context/PuzzleContext.js'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import Navbar from './components/navbar/Navbar'
import Loading from './components/misc/Loading'
import GoogleAdSense from './components/ads/GoogleAdSense'
import { getProximity, getStatuses, getGuessStatuses, NumberStatus } from './lib/statuses'
import { getGameStats, pushStats } from './lib/stats'
import { AnimatedHintStatus } from './state'
import { indexOfNextOpenCell, indexOfPreviousNonLockedCell, setProperCursorPosition } from './lib/utils'
import { MAX_GUESSES, TILE_REVEAL_TIME_MS, ROW_SLIDE_DELAY_TIME_MS } from './constants/settings'
import { findFirstClueNotRespected, getTodaysPuzzleAsync, nextPuzzleAvailable } from './lib/puzzles'
import { useAppStore } from './context/AppStoreContext'
import TriviaDetails from './components/trivia/TriviaDetails'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { twitterLogin } from './lib/login'

const App = () => {
    const store = useAppStore()
    const puzzle = store.puzzle
    const guesses = store.guesses
    const playerId = store.playerId
    const solution = store.solution
    const shouldPrefillCorrectDigits = store.prefillCorrectDigits
    const immediateTriviaReveal = store.immediateTriviaReveal
    const hasRevealedTrivia = store.triviaRevealed
    const [currentGuess, setCurrentGuess] = useState<string[]>([])
    const [cursorIndex, setCursorIndex] = useState(0)
    const [hasWon, setHasWon] = useState(false)
    const [hasLost, setHasLost] = useState(false)
    const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [currentRowClass, setCurrentRowClass] = useState('')
    const [showFullBoard, setShowFullBoard] = useState(false)
    const [isRevealing, setIsRevealing] = useState(false)
    const [isNewRow, setIsNewRow] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [instructionsRead, setInstructionsRead] = useState(localStorage.getItem('instructionsRead'))

    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()
    const isGameOver = hasWon || hasLost

    useEffect(() => {
        const fetchData = async () => {
            const data = await getTodaysPuzzleAsync()

            runInAction(() => store.puzzle = data)

            // Nothing was loaded or there was an error loading the puzzle
            if (isEmpty(data.Puzzle)) return;

            // New game: reset gameState
            if (puzzle?.Puzzle !== data.Puzzle) {
                setIsLoading(false)
                setCurrentGuess([])
                return runInAction(() => store.guesses = [])
            }

            if (!isEmpty(guesses)) {
                if (NumberStatus.CORRECT === getProximity(guesses[guesses.length - 1], solution)) {
                    setHasWon(true)
                } else if (store.areNoMoreGuesses) {
                    setHasLost(true)
                }
            }
            setIsLoading(false)
        }
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // If row animation is still running, don't show the new row's digitStatuses
    const digitStatuses = getStatuses(store.newRowAnimationFinished ? guesses : guesses.slice(0, -1), solution)

    useEffect(() => {
        invoke(document, `documentElement.classList.${store.isDarkMode ? 'add' : 'remove'}`, 'dark')
        invoke(document, `documentElement.classList.${store.highContrast ? 'add' : 'remove'}`, 'high-contrast')
    }, [store.isDarkMode, store.highContrast])

    useCallback(() => {
        if(window.location.pathname.indexOf("twitter_signin") > -1){

            twitterLogin(store, store.playerId, window.location.search)

            window.history.pushState("", "Wumber Trivia", "/")
        }
    }, [store])

    const onChar = useCallback((value: string) => {
        if (isGameOver || cursorIndex === -1) return

        var newGuess = [...currentGuess]
        newGuess[cursorIndex] = value
        setCurrentGuess(newGuess)
        setIsNewRow(false)
        setCursorIndex(indexOfNextOpenCell(newGuess, solution.length, cursorIndex))
    }, [isGameOver, currentGuess, cursorIndex, solution.length])

    const onDelete = useCallback(() => {
        const priorGuess = guesses.length === 0 ? '' : guesses[guesses.length - 1]
        const statuses = getGuessStatuses(shouldPrefillCorrectDigits ? priorGuess : '', solution)
        const deleteIndex = indexOfPreviousNonLockedCell(statuses, cursorIndex === -1 ? solution.length : cursorIndex)
        if (deleteIndex >= 0) {
            var newGuess = [...currentGuess]
            newGuess[deleteIndex] = ''
            setCurrentGuess(newGuess)
            setCursorIndex(deleteIndex)
        }
        setIsNewRow(false)
    }, [guesses, currentGuess, solution, cursorIndex, shouldPrefillCorrectDigits])

    const onEnter = useCallback(() => {
        if (isGameOver || indexOfNextOpenCell(currentGuess, solution.length) > -1) return

        const firstClueNotRespected = findFirstClueNotRespected(currentGuess, guesses, solution.toString())
        if (firstClueNotRespected) {
            setCurrentRowClass('jiggle')
            return showErrorAlert(firstClueNotRespected, {
                onClose: () => setCurrentRowClass(''),
            })
        }

        if (!immediateTriviaReveal)
            runInAction(() => store.triviaRevealed = false)   // Ensure trivia is not revealed except in TriviaAnswer component
        
        setCurrentRowClass('')              // Clear any previous errors
        
        if (!hasWon && guesses.length < MAX_GUESSES) {
            // Valid guess; accept into array
            runInAction(() => store.guesses = [...guesses, currentGuess.join('')])

            const numberStatus = getProximity(currentGuess.join(''), solution)
            const justWon = numberStatus === NumberStatus.CORRECT
            const justLost = !justWon && guesses.length === MAX_GUESSES - 1

            setIsNewRow(true)
            if (justWon || justLost) {
                runInAction(() => store.newRowAnimationFinished = true)
                setIsRevealing(true)
                setTimeout(() => setIsRevealing(false), TILE_REVEAL_TIME_MS * solution.length) 

            } else {
                // Allow for time for prior row to move up or down before revealing tile statuses
                runInAction(() => store.newRowAnimationFinished = false)
                setTimeout(() => {setIsRevealing(true); runInAction(() => store.newRowAnimationFinished = true)}, ROW_SLIDE_DELAY_TIME_MS)
                setTimeout(() => setIsRevealing(false), ROW_SLIDE_DELAY_TIME_MS + TILE_REVEAL_TIME_MS * solution.length) 
            }
            
            if (shouldPrefillCorrectDigits)
                setProperCursorPosition(currentGuess, guesses, solution, cursorIndex, setCursorIndex)
            else
                setCursorIndex(0)
            setCurrentGuess([])

            // Handle end game
            if (justWon) {
                runInAction(() => store.stats = getGameStats(store.stats, guesses.length))
                // NOTE: currentGuess hasn't been counted yet, so have to add 1
                pushStats(guesses.length + 1, playerId, store.stats, store.token)
                setHasWon(true)
            } else if (justLost) {
                runInAction(() => store.stats = getGameStats(store.stats, guesses.length + 1))
                pushStats(-1, playerId, store.stats, store.token)
                setHasLost(true)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzle, guesses, hasWon, hasLost, currentGuess, showErrorAlert, cursorIndex, shouldPrefillCorrectDigits])

    // Ensure the starting position is valid (needed for page refresh in middle of game)
    // If there are guesses, and the cursor may be default (0) or invalid (-1) and there are open cells
    if (guesses.length > 0 && cursorIndex <= 0 && indexOfNextOpenCell(currentGuess, solution.length) > -1) {
        if (shouldPrefillCorrectDigits)
            setProperCursorPosition(currentGuess, guesses, solution, cursorIndex, setCursorIndex)
        else if (cursorIndex !== 0)
            setCursorIndex(0)
    }

    // Reset animation chaining at start of each game
    if (guesses.length === 0) {
        runInAction(() => {store.animatedHintStatus = AnimatedHintStatus.NOTSTARTED; store.newRowAnimationFinished = true})
    }

    const guessesRemainingMessage = (guesses.length === 0) ? "Tap skill-stop to set first guess"
        : (guesses.length === MAX_GUESSES - 1) ? "Last guess!"
        : MAX_GUESSES - guesses.length + " guesses remaining"

    useEffect(() => {
        const onStorage = () => {
            setInstructionsRead(localStorage.getItem('instructionsRead'))
        };

        window.addEventListener('storage', onStorage)

        return () => {
            window.removeEventListener('storage', onStorage)
        }
    }, [instructionsRead])

    return (
        isLoading ? <Loading /> :
        <div className="h-full flex flex-col fixed-game justify-between">
            <Navbar hasWon={hasWon} setIsStatsModalOpen={setIsStatsModalOpen} setIsSettingsModalOpen={setIsSettingsModalOpen} />
            <div className="flex flex-col flex-grow-1 items-center px-1 h-full md:max-w-7xl w-full mx-auto sm:px-6 lg:px-8">
                <PuzzleContext.Provider value={puzzle}>
                    {isGameOver &&
                    <>
                        {!showFullBoard && 
                            <div className="flex flex-col mb-10 -mt-2 min-w-[300px] max-w-[600px] iPhonePro:scale-110 smish:scale-125 sm:scale-150">
                                <TriviaHints guessesMade={guesses.length} hasWon={hasWon} hasLost={hasLost} isTriviaRevealed={hasRevealedTrivia} />
                            </div>
                        }
                        <Board onEnter={onEnter} currentGuess={currentGuess} setCurrentGuess={setCurrentGuess} cursorIndex={cursorIndex} setCursorIndex={setCursorIndex}
                            isRevealing={isRevealing} isNewRow={isNewRow} currentRowClass={currentRowClass} setCurrentRowClass={setCurrentRowClass} hasWon={hasWon} showFullBoard={showFullBoard} />
                        {!showFullBoard &&
                            <TriviaDetails startGame={false}/>
                        }
                        <TriviaAnswer showFullBoard={showFullBoard} setIsStatsModalOpen={setIsStatsModalOpen}/>
                        {hasRevealedTrivia &&
                            <Animated animationIn='zoomIn' animationOut='zoomOut' animationInDelay={1000} animateOnMount isVisible={true}>
                                <div className="flex flex-col mb-10 min-w-[300px] max-w-[600px] iPhonePro:scale-110 smish:scale-125 sm:scale-150">
                                    <EndGameButtons hasLost={hasLost} showFullBoard={showFullBoard} setShowFullBoard={setShowFullBoard} setIsStatsModalOpen={setIsStatsModalOpen}/>
                                    <div className='flex gap-4 text-sm mt-2 text-slate-900 dark:text-slate-300 no-text-select'>
                                        <div className='absolute left-[66px]'>{NEW_PUZZLE_TEXT}&nbsp;</div>
                                        <div className='absolute left-[174px]'>
                                            <Countdown date={nextPuzzleAvailable} daysInHours={true} />
                                        </div>
                                        {/* <div className='absolute left-8'>TEST: New Wumber every 5 minutes</div> */}
                                    </div>
                                    <GoogleAdSense />
                                </div>
                            </Animated>
                        }
                    </>
                    }
                    {!isGameOver &&
                    <>
                        <div className="flex flex-col mb-6 xs:mb-10 min-w-[300px] max-w-[600px] iPhonePro:scale-110 smish:scale-125 sm:scale-150">
                            <TriviaHints guessesMade={guesses.length} hasWon={hasWon} hasLost={hasLost} />
                        </div>
                        <Board onEnter={onEnter} currentGuess={currentGuess} setCurrentGuess={setCurrentGuess} cursorIndex={cursorIndex} setCursorIndex={setCursorIndex}
                            isRevealing={isRevealing} isNewRow={isNewRow} currentRowClass={currentRowClass} setCurrentRowClass={setCurrentRowClass} hasWon={hasWon} showFullBoard={showFullBoard} />
                        <div className="flex text-lg mt-14 text-slate-400 no-text-select">{guessesRemainingMessage}</div>
                    </>
                    }
                    <div className="flex flex-col max-w-[600px]">
                        {hasWon && <Confetti recycle={false}/>}
                        <div className="py-2" />
                        <StatsModal
                            isOpen={isStatsModalOpen}
                            handleClose={() => setIsStatsModalOpen(false)}
                            hasLost={hasLost}
                            hasWon={hasWon}
                            handleShareToClipboard={() => hasWon ? showSuccessAlert(GAME_COPIED_MESSAGE) : showErrorAlert(GAME_COPIED_MESSAGE)}
                        />
                        <SettingsModal isOpen={isSettingsModalOpen} handleClose={() => setIsSettingsModalOpen(false)} />
                        <AlertContainer />
                    </div>
                </PuzzleContext.Provider>
            </div>
            <div className="w-full justify-center py-1">
                {!isGameOver && guesses.length > 0 &&
                    <Keyboard onChar={onChar} onDelete={onDelete} onEnter={onEnter} isRevealing={isRevealing} digitStatuses={digitStatuses}/>
                }
            </div>
        </div>
    )
}

export default observer(App)
