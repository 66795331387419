import Tile from './Tile'
import { DigitStatus, LogoStatus } from '../../lib/statuses'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../../context/AppStoreContext';

type Props = {
    logoStatus?: LogoStatus
}

const LogoRow = ({logoStatus = undefined} : Props) => {
    const store = useAppStore()
    const logoLetters: string[] = ['U', 'M', 'B', 'E', 'R']
    const classes = 'text-4xl font-semibold logo no-text-select pt-1'

    return (
        <div className="flex mr-9 text-black dark:text-white ">
            <div className={classes}>
                W
            </div>
            {logoLetters.map((char, i) => (
                <Tile key={i} value={char} status={DigitStatus.LOGO} position={i}
                    logoStatus={logoStatus !== undefined ? logoStatus : store.animateLogo ? LogoStatus.ANIMATED : LogoStatus.STATIC} />
            ))}
            &reg;
        </div>
    )
}

export default observer(LogoRow)
