import { useState, useEffect } from 'react'
import classnames from 'classnames'
import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import Histogram from '../stats/Histogram'
import { shareStatus } from '../../lib/share'
import { nextPuzzleAvailable } from '../../lib/puzzles'
import BaseModal from './BaseModal'
import {
    STATISTICS_TITLE,
    GUESS_DISTRIBUTION_TEXT,
    NEW_PUZZLE_TEXT,
    SHARE_TEXT,
    WIN_MESSAGES
} from '../../constants/strings'
import { useAppStore } from '../../context/AppStoreContext'
import { observer } from 'mobx-react-lite'
import { decodeSolution } from '../../lib/utils'

type Props = {
    isOpen: boolean
    handleClose: () => void
    hasLost: boolean
    hasWon: boolean
    handleShareToClipboard: () => void
}

const StatsModal = ({
    isOpen,
    handleClose,
    hasLost,
    hasWon,
    handleShareToClipboard,
}: Props) => {

    const store = useAppStore()
    const stats = store.stats
    const guesses = store.guesses
    const puzzle = store.puzzle
    const isHighContrast = store.highContrast

    const [winMessage, setWinMessage] = useState(STATISTICS_TITLE)

    const classes = classnames("mt-2 w-full rounded-sm border border-transparent px-4 py-2 text-base font-medium text-white",
    "shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2",
    {
        "bg-error": !isHighContrast && hasLost,
        "bg-error-hc": isHighContrast && hasLost,
        "bg-correct dark:bg-correct-dark": !isHighContrast && !hasLost,
        "bg-correct-hc": isHighContrast && !hasLost
    })

    useEffect(() => {
    if (hasWon && winMessage === STATISTICS_TITLE)
        setWinMessage(WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)])
    else if (hasLost)
        setWinMessage(STATISTICS_TITLE)
    }, [winMessage, setWinMessage, hasLost, hasWon])

    const modalTitle = winMessage

    if (stats.gamesPlayed <= 0) {
        return (
            <BaseModal title={winMessage} isOpen={isOpen} handleClose={handleClose}>
                <StatBar gameStats={stats} />
            </BaseModal>
        )
    }

    return (
        <BaseModal title={modalTitle} isOpen={isOpen} handleClose={handleClose}>
            <StatBar gameStats={stats} />
            <h4 className="text-base leading-6 text-white">
                {GUESS_DISTRIBUTION_TEXT}
            </h4>
            <Histogram hasLost={hasLost} />
            {(hasLost || hasWon) && (
                <div className="mt-5 columns-2 dark:slate-300 text-black dark:text-white">
                    <div>
                        <h5>{NEW_PUZZLE_TEXT}</h5>
                        <Countdown
                            className="text-xl text-black dark:text-white"
                            date={nextPuzzleAvailable}
                            daysInHours={true}
                        />
                        {/* <h5>TEST: New Wumber every 5 minutes</h5> */}
                    </div>
                    <button type="button" className={classes} tabIndex={1} autoFocus
                    onClick={() => {
                        shareStatus(
                        guesses,
                        hasLost,
                        decodeSolution(puzzle.Puzzle),
                        puzzle.UserName,
                        handleShareToClipboard,
                        stats
                        )
                    }}
                    >
                        {SHARE_TEXT}
                    </button>
                </div>
            )}
        </BaseModal>
    )
}

export default observer(StatsModal)
