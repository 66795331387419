import { getProximity, getGuessStatuses, NumberStatus, DigitStatus } from './statuses'
import { gameNumber, sortGuesses } from './puzzles'
import { MAX_GUESSES } from '../constants/settings'
import { WIN_ICON, LOSS_ICON, UP_ARROW, DOWN_ARROW } from '../constants/strings'
import { UAParser } from 'ua-parser-js'
import { GameStats } from './stats'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

const emojiTiles = ['🟩', '🟨', '🟥', '⬛']

export const shareStatus = (
    guesses: string[],
    lost: boolean,
    solution: string,
    userName: string,
    handleShareToClipboard: () => void,
    stats: GameStats
) => {

    const textToShare =
        `Wumber #${gameNumber <= 0 ? 'Beta' : gameNumber.toString()}: ${lost ? LOSS_ICON : guesses.length}/${MAX_GUESSES} ${lost ? '' : WIN_ICON}\nby ${userName}\n` +
        generateEmojiBoard(guesses,emojiTiles, solution, stats)

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  }
  catch (error) {
    shareSuccess = false
  }

  if (!shareSuccess) {
    navigator.clipboard.writeText(textToShare)
    handleShareToClipboard()
  }
}

export const generateEmojiBoard = (guesses: string[], tiles: string[], solution: string, gameStats: GameStats) => {
    const sortedGuesses = sortGuesses(guesses)
    const finalGuess = guesses[guesses.length - 1]
    const didLose = guesses.length === MAX_GUESSES && finalGuess !== solution

    let emojiBoard = sortedGuesses.map((guess, i) => {
        const hair_space = '\u200A'
        const status = getGuessStatuses(guess, solution)
        var proximity = didLose && guess === finalGuess ? NumberStatus.WRONG : getProximity(guess, solution)
        var upDownArrow = proximity === NumberStatus.HIGHER ? hair_space + hair_space + UP_ARROW + hair_space + hair_space
          : proximity === NumberStatus.LOWER ? hair_space + hair_space + DOWN_ARROW + hair_space + hair_space
          : proximity === NumberStatus.CORRECT ? WIN_ICON
          : proximity === NumberStatus.WRONG ? hair_space + hair_space + LOSS_ICON + hair_space + hair_space : ''

        return '\n' + guess.split('')
        .map((_, i) => {
          switch (status[i]) {
            case DigitStatus.CORRECT:
              return tiles[0]
            case DigitStatus.PRESENT:
              return didLose && guess === finalGuess ? tiles[2] : tiles[1]
            case DigitStatus.ABSENT:
                return didLose && guess === finalGuess ? tiles[2] : tiles[3]
            default:
                return tiles[3]
          }
        })
        .join('') + upDownArrow
    })
    .join('')

    if (!gameStats || gameStats.currentStreak <= 0)
        emojiBoard = emojiBoard + '\n'
    else if (gameStats.currentStreak === 1)
        emojiBoard = emojiBoard + '\n\nI won today!'
    else {
        const streak = gameStats.currentStreak.toString()
        const article = (streak.startsWith('8') || streak === '11' || streak === '18') ? 'an' : 'a'
        emojiBoard = emojiBoard + `\n\nI'm on ${article} ${gameStats.currentStreak}-day streak!`
    }
 
    return emojiBoard + '\nhttps://wumber.app'
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}
