import { isEmpty } from 'lodash-es'
import { useAppStore } from '../../context/AppStoreContext'

type Props = {
    startGame: boolean
}

const TriviaDetails = ({startGame} : Props) => {
  const store = useAppStore()
  const puzzle = store.puzzle
  return (
    <div className='text-slate-400'>
        <div className={'flex justify-center items-center text-xs no-text-select pt-3'}>
            {startGame &&
                <>
                Today's Wumber&nbsp;
                </>
            }
            by {puzzle.UserName}
        </div>
        {!isEmpty(puzzle.UserLocation) &&
            <div className={'flex justify-center items-center text-xs no-text-select -pt-2'}>
                in {puzzle.UserLocation}
            </div>
        }
        <div className={'flex justify-center items-center text-sm no-text-select pt-1 -mb-[3px]'}>
            <img className='w-12 rounded-full border-width-[2px]' src={puzzle.UserAvatar} alt="Avatar" />
        </div>
        { /* Commenting this out for now because there's no room to display it. May want to move this...   
        {startGame && puzzle.ActivePuzzleCount > 1 && puzzle.UserName !== 'Wumber BETA' &&
            <div className={'justify-center text-center text-sm no-text-select leading-0.5 mt-2 xs:visibility:hidden'}>
                <p>
                    Picked randomly from {puzzle.ActivePuzzleCount} entries
                </p>
            </div>
        }
        */}
    </div>
  )
}

export default TriviaDetails
