import { MAX_GUESSES, baseUrl } from '../constants/settings'

// In stats array elements 0-5 are successes in 1-6 trys

export type GameStats = {
  gamesPlayed: number
  winDistribution: number[]
  currentStreak: number
  bestStreak: number
  lastWin?: Date
}

export const getGameStats = (
  gameStats: GameStats,
  count: number // count is number of incorrect guesses before end.
) => {
  const stats = { ...gameStats }

  stats.gamesPlayed += 1

  if (count >= MAX_GUESSES) {
    stats.currentStreak = 0
  } else {
    stats.winDistribution[count] += 1
    stats.currentStreak += 1

    if (stats.currentStreak > stats.bestStreak) {
      stats.bestStreak = stats.currentStreak
    }

    stats.lastWin = new Date()
  }
  return stats
}

export const defaultStats: GameStats = {
  gamesPlayed: 0,
  winDistribution: Array.from(new Array(MAX_GUESSES), () => 0),
  currentStreak: 0,
  bestStreak: 0
}

export const getSuccessRate = (gameStats: GameStats) => {
  const wins = gameStats.winDistribution.reduce((partialSum, a) => partialSum + a, 0)
  return Math.round((100 * wins / Math.max(gameStats.gamesPlayed, 1)))
}

export const getCurrentStreak = (stats: GameStats): number => {
  const lastWin: any = stats.lastWin;
  if (lastWin) {
    //setHours(0, 0, 0, 0) clips the time off of the date based on local time. Divide to convert milliseconds to days
    const lastWinDay = new Date(Date.parse(lastWin)).setHours(0, 0, 0, 0) / (24 * 60 * 60 * 1000)
    const today = new Date().setHours(0, 0, 0, 0) / (24 * 60 * 60 * 1000)
    const dayDifference = today - lastWinDay
    if (dayDifference > 1)
      stats.currentStreak = 0
  }
  return stats.currentStreak
}

export const pushStats = (numGuesses: number, playerId: string, stats: GameStats, token: string) => {
  const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
  }

  try {
      var tzOffsetMinutes = new Date().getTimezoneOffset() * (-1)    // Minutes
      var requestUrl = `${baseUrl}/GameResult/?userId=${playerId}&tzOffsetMinutes=${tzOffsetMinutes}&numGuesses=${numGuesses}&isNewPlayer=${stats.gamesPlayed === 1}`
      if (numGuesses > 0 && stats.currentStreak >= stats.bestStreak)
          requestUrl = requestUrl + `&newBestStreak=${stats.bestStreak}`

      fetch(requestUrl, requestOptions)
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json')
          if (!isJson) {
              // Couldn't save stats
              // TODO: post to retry queue
              const errString = "Invalid response posting stats"
              console.error('ERROR', errString)
              return Promise.reject(errString)
          }

          const data = await response.json()

          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.result && data.result.status) || response.statusText;
              console.error('ERROR', error)
              return Promise.reject(error)
          }
      })
      .catch(error => {
          console.error('ERROR', error)
          return Promise.reject(error)
      })
  }
  catch (error) {
      console.error('ERROR', error)
      return Promise.reject(error)
  }
}
