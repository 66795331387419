import BaseModal from './BaseModal'
import { observer } from 'mobx-react'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const ComingSoonModal = ({ isOpen, handleClose }: Props) => {
    return (
        <BaseModal title="Create your Wumber&reg;" isOpen={isOpen} handleClose={handleClose}>
             <div className="container dark:text-white">
                    <p className="leading-6">
                        Create your own Wumber for a chance to win cash prizes<sup>*</sup>
                    </p>
                    <br />
                    <p className="mb-2">
                        Coming Soon! 
                    </p>
                    <br />
                    <p className="mb-2 leading-4">
                        <sup>* - NO PURCHASE NECESSARY.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Alternate method of entry available.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Full sweepstakes details will be posted upon launch.</sup>
                    </p>
                </div>
        </BaseModal>
    )
}

export default observer(ComingSoonModal)
