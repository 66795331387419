import BaseModal from './BaseModal'
import SettingsToggle from './SettingsToggle'
import {
    HIGH_CONTRAST_MODE_DESCRIPTION,
    DARK_MODE_DESCRIPTION,
    LEFT_HAND_KEYBOARD_DESCRIPTION,
    PREFILL_CORRECT_DIGITS_DESCRIPTION,
    IMMEDIATE_TRIVIA_REVEAL_DESCRIPTION,
    ANIMATE_LOGO_DESCRIPTION
} from '../../constants/strings'
import { useAppStore } from '../../context/AppStoreContext'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

type Props = {
    isOpen: boolean
    handleClose: () => void
}

const SettingsModal = ({
    isOpen,
    handleClose,
}: Props) => {
    const store = useAppStore()
    const animateLogo = store.animateLogo
    const isHighContrastMode = store.highContrast
    const isLeftyConfig = store.leftyConfiguration
    const shouldPrefillCorrectDigits = store.prefillCorrectDigits
    const immediateTriviaReveal = store.immediateTriviaReveal
    return (
        <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
            <div className="flex flex-col divide-y -mt-2 mr-1">
                <SettingsToggle
                    settingName="Correct Digit Auto-Fill"
                    flag={shouldPrefillCorrectDigits}
                    handleFlag={(val:boolean) => runInAction(() => store.prefillCorrectDigits = val)}
                    description={PREFILL_CORRECT_DIGITS_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
                <SettingsToggle
                    settingName="Immediate Trivia Reveal"
                    flag={immediateTriviaReveal}
                    handleFlag={(val:boolean) => runInAction(() => store.immediateTriviaReveal = val)}
                    description={IMMEDIATE_TRIVIA_REVEAL_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
                <SettingsToggle
                    settingName="Left-Handed Keypad"
                    flag={isLeftyConfig}
                    handleFlag={(val:boolean) => runInAction(() => store.leftyConfiguration = val)}
                    description={LEFT_HAND_KEYBOARD_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
                <SettingsToggle
                    settingName="Dark Theme"
                    flag={store.isDarkMode}
                    handleFlag={(val:boolean) => runInAction(() => store.theme = val ? 'dark' : 'light')}
                    description={DARK_MODE_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
                <SettingsToggle
                    settingName="High Contrast Mode"
                    flag={isHighContrastMode}
                    handleFlag={(val:boolean) => runInAction(() => store.highContrast = val)}
                    description={HIGH_CONTRAST_MODE_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
                <SettingsToggle
                    settingName="Animated Logo"
                    flag={animateLogo}
                    handleFlag={(val:boolean) => runInAction(() => store.animateLogo = val)}
                    description={ANIMATE_LOGO_DESCRIPTION}
                    isHighContrastMode={isHighContrastMode}
                />
            </div>
        </BaseModal>
    )
}

export default observer(SettingsModal)
