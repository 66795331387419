export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ["Simply Wumberful!", "You're my Wumberwall!", "You have my Wumber!", "You're a Wumberkind!", "Wumber how you did that?"]
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const DARK_MODE_DESCRIPTION = 'Easier on your eyes'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Improved color visualization'
export const LEFT_HAND_KEYBOARD_DESCRIPTION = 'Moves Guess and Delete keys to the left'
export const IMMEDIATE_TRIVIA_REVEAL_DESCRIPTION = 'Show trivia immediately after last guess'
export const PREFILL_CORRECT_DIGITS_DESCRIPTION = 'Prefill correct digits from prior guesses'
export const ANIMATE_LOGO_DESCRIPTION = 'Cute or annoying?'

export const CORRECT_DIGIT_MISSING_MESSAGE = (digit: string, position: number) => `${getDigitAndPositionSuffix(position)} digit must be ${getArticleAndDigit(digit)}`
export const FIRST_DIGIT_MISSING_MESSAGE = (digit: string) => `First digit must be ${getArticleAndDigit(digit)}`
export const LAST_DIGIT_MISSING_MESSAGE = (digit: string) => `Last digit must be ${getArticleAndDigit(digit)}`
export const NOT_CONTAINED_MESSAGE = (digits: string[]) => `Guess is missing ${formatList(digits)}`
export const MUST_BE_HIGHER_MESSAGE = (otherGuess: string) => `Guess higher than ${otherGuess}`
export const MUST_BE_LOWER_MESSAGE = (otherGuess: string) => `Guess lower than ${otherGuess}`
export const MUST_BE_BETWEEN_MESSAGE = (loGuess: string, hiGuess: string) => `Guess between ${loGuess} and ${hiGuess}`
export const ENTER_TEXT = 'Guess'
export const WIN_ICON           = '\u2605'  // star
export const LOSS_ICON          = '\u2717'  // X
export const DELETE_KEY_ICON    = '\u232b'
export const UP_ARROW           = '\u2191'
export const DOWN_ARROW         = '\u2193'
export const LEFT_ARROW         = '\u2190'
export const RIGHT_ARROW        = '\u2192'
export const MIDDLE_DOT         = '\u00b7'
export const STATISTICS_TITLE = "How you doin'?"
export const GUESS_DISTRIBUTION_TEXT = 'Guesses to Win'
export const NEW_PUZZLE_TEXT = 'New Wumber in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Game Plays'
export const SUCCESS_RATE_TEXT = '% Won'
export const CURRENT_STREAK_TEXT = 'This Streak'
export const BEST_STREAK_TEXT = 'Best Streak'

export const TRIVIA_COUNTDOWN_TEXT = 'Trivia will show in'
export const SHOW_TRIVIA_TEXT = 'Show Now'
export const SHOW_BOARD_TEXT = 'Show Guesses'
export const HIDE_BOARD_TEXT = 'Show Solution'
export const SHOW_STATS_TEXT = "Stats/Share"
export const LEARN_MORE_TEXT = "Learn More"

const getArticleAndDigit = (digit: string) : string => {
    return (digit === '8' ? 'an ' : 'a ') + digit
}

const getDigitAndPositionSuffix = (position: number) : string => {
    let pos = position + 1
    return pos.toString() + (pos === 1 ? 'st' : pos === 2 ? 'nd' : pos === 3 ? 'rd' : 'th')
}

const formatList = (items : string[]) : string => {
    if (items.length === 0)
        return ''

    if (items.length === 1)
        return getArticleAndDigit(items[0])

    var digitItems = new Array<Number>()
    items.forEach((item) => digitItems.push(Number(item)))
    digitItems.sort()

    if (digitItems.length === 2)
        return digitItems[0] === digitItems[1] ? `two ${digitItems[0].toString()}s`
            : `${getArticleAndDigit(digitItems[0].toString())} and ${getArticleAndDigit(digitItems[1].toString())}`

    var text = ' '
    digitItems.forEach((item, i) => {
        if (i !== items.length - 1)
            text = text + item.toString() + ', '
        else
            text = text + 'and ' + item.toString()
    })

    return text
}