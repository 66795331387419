import { LogoStatus } from '../../lib/statuses'
import LogoRow from '../board/LogoRow'

const Loading = () => {
  return (
    <>
        <div className='h-full w-full grid justify-items-center items-center dark:text-slate-300'>
            <div className='text-center'>
                Loading
                <div style={{ position: "relative", left: "10px" }}>
                    <LogoRow logoStatus={LogoStatus.QUICK}/>
                </div>
            </div>
        </div>
    </>
  )
}
export default Loading
