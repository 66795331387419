import classnames from 'classnames'
import { MAX_DIGITS } from '../../../constants/settings'
import CompletedRow from '../../board/CompletedRow'
import { NumberStatus } from '../../../lib/statuses'

type Props = {
    statusText?: string,
    isReserved: boolean,
    solution: string,
    setSolution: (value: string) => void
}

const NumberPage = ({statusText, isReserved, solution, setSolution}: Props) => {
    const classes = "mb-2 no-text-select"
    const numberInputClasses = classnames(`my-3 w-5/12 font-bold border border-slate-300 border-solid text-2xl text-center py-2 px-3 dark:bg-white dark:text-black rounded-sm ${isReserved ? 'no-text-select': ''}`)
    
    return (
        <div className={classes}>
            {isReserved &&
                <>
                    <div className={`scale-125 my-4`} >
                    <CompletedRow key={solution} guess={solution} proximity={NumberStatus.CORRECT} isRevealing standAlone/>
                    </div>
                </>
            }
            {!isReserved &&
                <>
                    <p className="mb-2 leading-5 text-base">
                        Enter a 2-6 digit number without decimal points, slashes or minus signs.
                    </p>
                    <p className="mb-6 leading-5 text-base">
                        If leading zeros are significant (e.g. 007 for James Bond), enter them.
                    </p>
                    <div className={`${classes} scale-125 flex justify-center items-center`} >
                        <input id='solutionInput' type="text" className={numberInputClasses}
                            pattern="\d*" value={solution} size={MAX_DIGITS+1} maxLength={MAX_DIGITS} tabIndex={0} autoFocus
                            onChange={(e) => setSolution(e.currentTarget.value)}
                        />
                    </div>
                </>
            }
            {typeof statusText !== 'undefined' &&
                <p className="mt-2 mb-2 leading-5 text-base">
                    {statusText}
                </p>
            }
        </div>
    )
}

export default NumberPage
