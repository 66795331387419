import { AppState } from "../state";
import { baseUrl } from '../constants/settings';
import { runInAction } from 'mobx'

export const twitterLogin = (store: AppState, userId: string, oauthParams: string) => {
    const tokenBlob = new Blob([JSON.stringify({
        oauthParams: oauthParams,
        userId: userId
      }, null, 2)], { type: 'application/json' });

      login(tokenBlob, store, "/auth/twitter");
  }

  const login = (tokenBlob: Blob, store: AppState, apiPath: string) =>{
      const options: RequestInit = {
        method: 'POST',
        body: tokenBlob,
        mode: 'cors',
        cache: 'default'
      };
      fetch(`${baseUrl}${apiPath}`, options)
        .then(r => {
          r.json().then(user => {
            runInAction(() => {
              store.token = user.token
              store.userName = user.userName
              store.playerId = user.userId

              window.history.pushState("", "Wumber", "/");
            });
          });
        })
        .catch(error => {
          console.error('ERROR', error)
          return Promise.reject(error);
      })
  }