import classnames from 'classnames'
import { useState, useCallback } from "react"
import BaseModal from './BaseModal'
import StatusPage from './CreateWumberPages/1_StatusPage'
import NumberPage from './CreateWumberPages/2_NumberPage'
import TriviaPage from './CreateWumberPages/3_TriviaPage'
import ConfirmationPage from './CreateWumberPages/5_ConfirmationPage'
import EndSubmitPage from './CreateWumberPages/6_EndSubmitPage'
import { baseUrl } from '../../constants/settings'
import { observer } from 'mobx-react'
import { useAppStore } from '../../context/AppStoreContext'
import { TailSpin } from 'react-loader-spinner'
import { LoginModal } from './LoginModal'

const NUM_PAGES = 5
const NONE = 0
const STATUS_PAGE = 1
const NUMBER_PAGE = 2
const TRIVIA_PAGE = 3
const CONFIRMATION_PAGE = 4
const END_SUBMIT_PAGE = 5

const navButtonsContainer = classnames("flex justify-between pt-2")

type Props = {
    isOpen: boolean
    hasWon: boolean
    handleClose: () => void
}

const isAllDigits = (strToCheck: string) : boolean => {
    for (var i = 0; i < strToCheck.length; i++)
        if (strToCheck[i] < '0' || strToCheck[i] > '9')
            return false
    return true
}

const CreateWumberModal = ({ isOpen, hasWon, handleClose }: Props) => {
    const store = useAppStore()
    const isHighContrast = store.highContrast

    const [pageNum, setPageNum] = useState(0)

    const [playerSolution, setPlayerSolution] = useState('')
    const [playerTrivia, setPlayerTrivia] = useState('')
    const [playerTopic, setPlayerTopic] = useState('')
    const [reserveNumberStatusText, setReserveNumberStatusText] = useState('')
    const [isNumberReserved, setIsNumberReserved] = useState(false)
    const [submitNumberStatusText, setSubmitNumberStatusText] = useState('')
    const [isNumberSubmitted, setIsNumberSubmitted] = useState(false)
    const [isApiCallInFlight, setIsApiCallInFlight] = useState(false)
    const [didAgreeToTerms, setDidAgreeToTerms] = useState(false)

    if (pageNum === NONE) {
        setPageNum(isNumberReserved ? NUMBER_PAGE : STATUS_PAGE)
    }

    const DecrementPage = useCallback(() => {setPageNum(pageNum - 1) }, [pageNum, setPageNum])
    const IncrementPage = useCallback(() => {
        function reserveNumberAsync() {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.token}` },
                body: JSON.stringify({})
            }
            // Prevent double-submission
            if (isApiCallInFlight || isNumberReserved || !isAllDigits(playerSolution))
                return

            try {
                setIsApiCallInFlight(true)

                const requestUrl = `${baseUrl}/Reserve/${playerSolution}/?userId=${store.playerId}`
                fetch(requestUrl, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json')
                    if (!isJson) {
                        setIsApiCallInFlight(false)
                        const errorText = "Error Checking Status. Please try again."
                        setReserveNumberStatusText(errorText)
                        setIsNumberReserved(false)
                        return Promise.reject(errorText)
                    }

                    const data = await response.json()
                    setIsApiCallInFlight(false)

                    if (response.ok) {
                        setIsNumberReserved(true)
                        setReserveNumberStatusText(`You got it!\n
                        Finish creating your Wumber before midnight (Eastern Time) or ${playerSolution} will be released.`)
                    } else {
                        // get error message from body or default to response status
                        const error = (data && data.result && data.result.status) || response.status;
                        console.error('ERROR', error)
                        setIsNumberReserved(false)
                        return Promise.reject(error)
                    }
                })
                .catch(error => {
                    console.error('ERROR', error)
                    let errorMsg = error.substring('Failed to fetch') !== -1 ? "Web service unavailable"
                        : error === 'AlreadyTaken' ? "Not available"
                        : "Must be logged in"
                    setIsNumberReserved(false)
                    setReserveNumberStatusText(errorMsg)
                    setIsApiCallInFlight(false)
                    return Promise.reject(error)
                })
            }
            catch (error) {
                console.error('ERROR', error)
                    setIsNumberReserved(false)
                    setReserveNumberStatusText("Web service error")
                    setIsApiCallInFlight(false)
                    return Promise.reject(error)
            }
            // NOTE: execution continues and returns before async call completes
        }

        function submitNumberAsync() {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.token}` },
                body: '[]'
            }

            // Prevent double-submission
            if (isApiCallInFlight || isNumberSubmitted)
                return

            setIsApiCallInFlight(true)

            const playerId = store.playerId
            const requestUrl = `${baseUrl}/Create/${playerSolution}/?userId=${playerId}&topic=${playerTopic}&trivia=${encodeURIComponent(playerTrivia)}&topic=${playerTopic}&verifyUrl=${encodeURIComponent("https://www.nytimes.com")}`
            fetch(requestUrl, requestOptions)
            .then(async response => {

                const isJson = response.headers.get('content-type')?.includes('application/json')
                if (!isJson) {
                    setIsNumberSubmitted(false)
                    const errorText = "Error Submitting Wumber. Please try again."
                    setSubmitNumberStatusText(errorText)
                    setIsApiCallInFlight(false)
                    return Promise.reject(errorText)
                }
                const data = await response.json()
                setIsApiCallInFlight(false)

                if (response.ok) {
                    setIsNumberSubmitted(true)
                    setIsApiCallInFlight(false)
                    setSubmitNumberStatusText("Submitted!")
                } else {
                    // get error message from body or default to response status
                    const error = (data && data.result && data.result.status) || response.statusText;
                    console.error('ERROR', error)
                    setIsNumberSubmitted(false)
                    return Promise.reject(error)
                }
            })
            .catch(error => {
                console.error('ERROR', error)
                setIsNumberSubmitted(false)
                setSubmitNumberStatusText(error === 409 ? "Not available" : error)
                setIsApiCallInFlight(false)
                return Promise.reject(error)
            })

            // NOTE: execution continues and returns before async call completes
        }

        if (pageNum === NUMBER_PAGE) {
            if (isNumberReserved)
                setPageNum(pageNum + 1)
            else if (playerSolution !== '')      // TODO: disable Next button until filled in
                reserveNumberAsync()
            // else Next button doesn't do anything
        } else if (pageNum === CONFIRMATION_PAGE) {
            isNumberSubmitted ? setPageNum(pageNum + 1) : submitNumberAsync()     // TODO need spinner
        } else if (pageNum < NUM_PAGES) {
            setPageNum(pageNum + 1)
        } else {
            handleClose()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum, setPageNum, playerSolution, playerTrivia, playerTopic, isNumberReserved, isNumberSubmitted, isApiCallInFlight])

    const nextButtonDisabled = (pageNum === NUMBER_PAGE && (playerSolution.length < 2 || playerSolution.length > 6))
        || (pageNum === TRIVIA_PAGE && (playerTrivia.length < 4 || playerTopic.length === 0))
        || (pageNum === CONFIRMATION_PAGE && !didAgreeToTerms)
        || isApiCallInFlight

    const isLoggedIn = store.userName.length > 0

    return (
        <BaseModal title="Create your Wumber&reg;" isOpen={isOpen} handleClose={handleClose} containerClass='min-h-[300px]'>
            <div className='flex justify-between flex-col h-full'>
                {pageNum === STATUS_PAGE &&
                    <StatusPage hasWon={hasWon} />
                }
                {pageNum === NUMBER_PAGE &&
                    <>
                        <LoginModal isOpen={!isLoggedIn} handleClose={() => {if (!isLoggedIn) DecrementPage()}}/>
                        <NumberPage isReserved={isNumberReserved} solution={playerSolution} 
                            setSolution={setPlayerSolution} statusText={reserveNumberStatusText} />
                    </>
                }
                {pageNum === TRIVIA_PAGE &&
                    <TriviaPage topic={playerTopic} setTopic={setPlayerTopic} trivia={playerTrivia} setTrivia={setPlayerTrivia} />
                }
                {pageNum === CONFIRMATION_PAGE &&
                    <ConfirmationPage solution={playerSolution} trivia={playerTrivia} topic={playerTopic} statusText={submitNumberStatusText} didAgree={didAgreeToTerms} setDidAgree={setDidAgreeToTerms} />
                }
                {pageNum === END_SUBMIT_PAGE &&
                    <EndSubmitPage />
                }
            </div>
            <div className={navButtonsContainer}>
                <div>
                    {pageNum > 1 &&
                        <button type="button" tabIndex={-1} className='btn text-sm' onClick={DecrementPage}>
                            Previous
                        </button>
                    }
                </div>
                <div>
                    {hasWon &&
                        <div className='flex justify-content-between gap-6'>
                            {(pageNum === NUMBER_PAGE || pageNum === CONFIRMATION_PAGE) && isApiCallInFlight && <TailSpin height="36px" width="36px" />}
                            <button type="button" tabIndex={100} className={`${isHighContrast ? 'btn-orange' : 'btn-green'} text-sm`}
                                onClick={IncrementPage} disabled={nextButtonDisabled}>
                                {pageNum >= NUM_PAGES ? 'Done' : pageNum === CONFIRMATION_PAGE ? 'Submit' : 'Next'}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </BaseModal>
    )
}

export default observer(CreateWumberModal)
