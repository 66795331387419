import { useEffect } from "react"

type Props = {
  handleFacebookSuccessResponse: (response: any) => void
  handleFacebookFailureResponse: (response: any) => void
}

declare global {
  interface Window {
    FB: any;
  }
}

const FacebookButton = ({ handleFacebookSuccessResponse, handleFacebookFailureResponse }: Props) => {
  useEffect(() => {
    const params = {
      appId: '1091725991690615',
      autoLogAppEvents: true,
      xfbml: false, // SDK will parse your page's DOM to find and initialize any social plugins that have been added using XFBML. setting xfbml to false will improve page load times
      version: 'v13.0'
    }

    window.FB.init(params);
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    window.FB.getLoginStatus(function (response: any) {
      console.log(response)
      if (response.status === 'connected') {
        console.log("User already logged in");
        handleFacebookSuccessResponse(response);
      } else {
        console.log("User not already logged in");
        window.FB.login(function (response: any) {
          if(response.status === 'connected'){
            handleFacebookSuccessResponse(response);
          } else {
            handleFacebookFailureResponse(response);
          }
        },
        {scope: 'public_profile,email,user_age_range,user_location'});
      }
    }, {scope: 'public_profile,email,user_age_range,user_location'});
  }


  return <div>
    <button className="text-blue-500 bg-white border-solid text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-7 h-7" style={{ color: '#1877f2' }}><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
      <span>Continue with Facebook</span>
    </button>
  </div>;
}

export default FacebookButton