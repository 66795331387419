import { useEffect } from "react"

declare global {
    interface Window {
        adsbygoogle: any;
    }
}

const GoogleAdSense = () => {
    useEffect(() => {
        const pushAd = () => {
            try {
                const adsbygoogle = window.adsbygoogle
                console.log({ adsbygoogle })
                adsbygoogle.push({})
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            // Push ad every 2 minutes if there's a new page load
            if (window.adsbygoogle) {
                pushAd()
                // clear the interval once the ad is pushed so that function isn't called indefinitely
                clearInterval(interval)
            }
        }, 120)

        return () => {
            clearInterval(interval)
        }
    }, [])
    return (
        <ins className="adsbygoogle"
            style={{display: "inline-block"}}
            data-adtest="on"
            data-ad-client="ca-pub-2183846883859727"
            data-ad-slot="8876867995"
        />
    )
}

export default GoogleAdSense

