import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

type Props = {
  title: string
  children: React.ReactNode
  isOpen: boolean
  handleClose: () => void
  containerClass?: string,
}

const BaseModal = ({ title, children, isOpen, handleClose, containerClass = '' }: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen py-5 px-4 text-center sm:w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white rounded-lg w-full overflow-y-auto shadow-lg transform transition-all dark:bg-gray-900 md:max-w-[55%] lg:max-w-[40%]">
              <div className='flex justify-between min-w-[20%] p-2 pl-5 border border-solid border-slate-200 dark:border-slate-700'>
                <Dialog.Title className="text-base text-gray-500 dark:text-slate-400 py-[3px]">
                  {title}
                </Dialog.Title>
                <div className="pt-[5px] pr-1">
                  <XIcon className="h-5 w-5 cursor-pointer stroke-slate-400" onClick={handleClose} />
                </div>
              </div>
              <div className={classNames('flex flex-col justify-between p-5 pt-2 mt-1 text-left text-slate-700 dark:text-slate-300 h-full',
              containerClass)}>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BaseModal
