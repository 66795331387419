import classnames from 'classnames'
import { observer } from 'mobx-react'
import { DigitStatus, LogoStatus, WaitStatus } from '../../lib/statuses'
import { AnimatedHintStatus } from '../../state'
import { TILE_REVEAL_TIME_MS } from '../../constants/settings'
import { useAppStore } from '../../context/AppStoreContext'

type Props = {
    value?: string
    status?: DigitStatus
    isRevealing?: boolean
    waitStatus?: WaitStatus
    position?: number
    isCursor?: boolean
    handleClick?: React.MouseEventHandler<HTMLDivElement>
    logoStatus?: LogoStatus
    wasPrefilled?: boolean
    invisible?: boolean
}

const ignoreClick: React.MouseEventHandler<HTMLDivElement> = (e) => {}

const Tile = ({
    value,
    status,
    isRevealing,
    waitStatus = WaitStatus.NONE,
    position = 0,
    isCursor = false,
    handleClick = ignoreClick,
    logoStatus = undefined,
    wasPrefilled = false,
    invisible = false
}: Props) => {
    const store = useAppStore()
    const isHighContrast = store.highContrast
    const isWaiting = waitStatus !== WaitStatus.NONE && waitStatus !== WaitStatus.CURRENTROW_NOTWAITING

    const isLogo = status === DigitStatus.LOGO
    const isCorrectDigitPending = waitStatus === WaitStatus.CURRENTROW_NOTWAITING
        && (wasPrefilled || (status === DigitStatus.CORRECT && !isWaiting))
        && store.animatedHintStatus !== AnimatedHintStatus.FINISHED
    const isCorrect = !isCorrectDigitPending
        && (wasPrefilled || (status === DigitStatus.CORRECT && !isWaiting))
    const isPresent = status === DigitStatus.PRESENT && !isWaiting
    const isAbsent = status === DigitStatus.ABSENT && !isWaiting
    const isError = status === DigitStatus.ERROR && !isWaiting
    const isNewDigit = value && (!status || (waitStatus === WaitStatus.COMPLETEDROW && !isCorrect))
    const shouldOutline = isCursor && store.animatedHintStatus !== AnimatedHintStatus.RUNNING
    const shouldStyleAsEmpty = !invisible && (waitStatus === WaitStatus.CURRENTROW
        || (!value && !status)
        || isCorrectDigitPending)
    const animationDelay = `${(isLogo ? 1.5 : 1.0) * position * TILE_REVEAL_TIME_MS}ms`

    const classes = classnames(
        `flex items-center justify-center font-bold border border-solid text-white
            ${isLogo && logoStatus === LogoStatus.ANIMATED ? 'text-base text-bold w-6 h-6 mt-[5px] m-px live-logo border-0'
            : isLogo && logoStatus === LogoStatus.QUICK ? 'text-base text-bold w-6 h-6 mt-[5px] m-px live-logo-fast border-0'
            : isLogo && logoStatus === LogoStatus.STATIC ? 'text-base text-bold bg-unknown text-black dark:text-white w-6 h-6 mt-[5px] m-px static-logo border-0'
            : 'text-2xl w-9 h-9 mx-0.5'}`,
        {
            'bg-unknown border-unknown dark:bg-unknown-dark dark:border-unknown-dark' : isLogo,                                         // logo cells
            'animate-cursor-blink dark:animate-cursor-blink-dark text-black dark:text-white border-2' : shouldOutline,                  // current row, cursor position
            'bg-empty dark:bg-empty-dark border-black dark:border-slate-100 border-2 text-black dark:text-white': isNewDigit,           // current row, filled cell
            'error bg-error border-error': isError && !isHighContrast,                                                                  // error condition - set on completed row at game loss
            'error bg-error-hc border-error-hc': isError && isHighContrast,                                                             // error condition (high contrast)
            'correct bg-correct dark:bg-correct-dark border-0': isCorrect && !isHighContrast,                                           // completed/current row, digit correct
            'correct bg-correct-hc border-correct-hc': isCorrect && isHighContrast,                                                     // completed/current row, digit correct (high contrast)
            'present bg-present border-present dark:bg-present-dark dark:border-present-dark': isPresent && !isHighContrast,            // completed row, digit present
            'present bg-present-hc border-present-hc': isPresent && isHighContrast,                                                     // completed row, digit present (high contrast)
            'absent bg-absent border-absent dark:bg-absent-dark dark:border-absent-dark' : isAbsent,                                    // completed row, digit not present
            'bg-empty dark:bg-empty-dark border-emptyborder dark:border-emptyborder-dark border-2': shouldStyleAsEmpty,                 // empty position OR row waiting to show status
            'cell-reveal': isRevealing && !wasPrefilled,                                                                                // revealing unknown tile
            'cell-reveal-prefill': isRevealing && wasPrefilled,                                                                         // revealing prefilled tile
            'border-0': invisible                                                                                                       // placeholder for spacing
        }
    )

    if (waitStatus === WaitStatus.CURRENTROW || isCorrectDigitPending)
        value = ''

    return (
        <>
            <div className={classes} style={{ animationDelay }} onClick={handleClick}>
                <div className="no-text-select" style={{ animationDelay }} onClick={handleClick}>
                    {value}
                </div>
            </div>
        </>
    )
}

export default observer(Tile)
