import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

type Props = {
    isOpen: boolean
    message: string
    variant?: 'success' | 'error'
}

export const Alert = ({ isOpen, message, variant = 'error' }: Props) => {
  const classes = classNames(
    'fixed z-50 h-12 top-0 left-1/2 min-w-[185px] w-full transform -translate-x-1/2 shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden',
    {
      'bg-error text-white': variant === 'error',
      'bg-correct dark:bg-correct-dark text-white': variant === 'success',
    }
  )

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition ease-in-out duration-1000 transform"
      enterFrom="-translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-1000 transform"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-full"
    >
      <div className={classes}>
        <p className="p-4 text-sm text-center no-text-select font-medium">{message}</p>
      </div>
    </Transition>
  )
}
