
export enum DigitStatus {
    ABSENT = 'absent',          // Digit is not present in any position
    PRESENT  = 'present',       // Correct digit but incorrect placement
    CORRECT = 'correct',        // Correct digit and placement
    ERROR = 'error',            // Incorrect digit shown upon loss
    LOGO = 'logo'               // Small gray logo background tile
}
export enum NumberStatus {
    HIGHER = 'higher',          // Solution is higher than guess
    LOWER =  'lower',           // Solution is lower than guess
    CORRECT = 'correct',
    WRONG = 'wrong',
}

export enum LogoStatus {
    ANIMATED = 'live-logo',
    QUICK = 'live-logo-fast',
    STATIC = 'static-logo'
}

export enum WaitStatus {
    NONE = 'none',
    CURRENTROW = 'current-row',
    CURRENTROW_NOTWAITING = 'current-row-not-waiting',
    COMPLETEDROW = 'completed-row'
}

export const getProximity = (guess: string, solution: string): NumberStatus => {
    // This isn't meant to handle *wrong* status... when you want to display that, just pass 'wrong' for proximity param to CompletedRow
    if (Number(guess) > Number(solution))
        return NumberStatus.LOWER
    if (Number(guess) < Number(solution))
        return NumberStatus.HIGHER

    return NumberStatus.CORRECT
}

export const getStatuses = (
    guesses: string[], solution: string
): { [key: string]: DigitStatus } => {
    const charObj: { [key: string]: DigitStatus } = {}
    const splitSolution = solution.split('')

    guesses.forEach((guess) => {
        guess.split('').forEach((digit, i) => {
        if (!splitSolution.includes(digit)) {
            return (charObj[digit] = DigitStatus.ABSENT)
        }

        if (digit === splitSolution[i]) {
            return (charObj[digit] = DigitStatus.CORRECT)
        }

        if (charObj[digit] !== 'correct') {
            return (charObj[digit] = DigitStatus.PRESENT)
        }
        })
    })

    return charObj
}

export const getGuessStatuses = (guess: string, solution: string): DigitStatus[] => {
    // no guess, set each char to something which doesn't match
    if (guess === '')
        guess = ' '.repeat(solution.length)

    const splitSolution = solution.split('')
    const splitGuess = guess.split('')

    const solutionCharsUsed = splitSolution.map((_) => false)
    const statuses: DigitStatus[] = Array.from(Array(guess.length))

    // handle correct locations first
    splitGuess.forEach((digit, i) => {
        if (digit === splitSolution[i]) {
            statuses[i] = DigitStatus.CORRECT
            solutionCharsUsed[i] = true
            return
        }
    })

    splitGuess.forEach((digit, i) => {
        if (statuses[i]) return

        if (!splitSolution.includes(digit)) {
            statuses[i] = DigitStatus.ABSENT
            return
        }

        // Find present characters
        const indexOfPresentChar = splitSolution.findIndex((x, index) => x === digit && !solutionCharsUsed[index])
        if (indexOfPresentChar > -1) {
            statuses[i] = DigitStatus.PRESENT
            solutionCharsUsed[indexOfPresentChar] = true
            return
        }

        // Anything left is absent
        statuses[i] = DigitStatus.ABSENT
    })

    return statuses
}

export const getGuessStatusesFromArray = (guess: string[], solution: string): DigitStatus[] => {
    // TODO: need to protect against guess being undefined?
    const splitSolution = solution.split('')
    const solutionCharsUsed = splitSolution.map((_) => false)
    const statuses: DigitStatus[] = Array.from(Array(guess.length))

    // handle correct locations first
    guess.forEach((digit, i) => {
        if (digit === splitSolution[i]) {
            statuses[i] = DigitStatus.CORRECT
            solutionCharsUsed[i] = true
            return
        }
    })

    guess.forEach((digit, i) => {
        if (statuses[i]) return

        if (!splitSolution.includes(digit)) {
            statuses[i] = DigitStatus.ABSENT
            return
        }

        // Find present characters
        const indexOfPresentChar = splitSolution.findIndex((x, index) => x === digit && !solutionCharsUsed[index])
        if (indexOfPresentChar > -1) {
            statuses[i] = DigitStatus.PRESENT
            solutionCharsUsed[indexOfPresentChar] = true
            return
        }

        // Anything left is absent
        statuses[i] = DigitStatus.ABSENT
    })

    return statuses
}

