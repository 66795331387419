import classnames from 'classnames'
import MiniHeader from '../misc/MiniHeader'
import TriviaDetails from '../../components/trivia/TriviaDetails'
import { useAppStore } from '../../context/AppStoreContext'
import { MAX_GUESSES } from '../../constants/settings'
import { AnimatedHintStatus } from '../../state'
import { observer } from 'mobx-react'
import { Animated } from 'react-animated-css'
import classNames from 'classnames'
import { uniqueId } from 'lodash-es'
import { useEffect } from 'react'

type Props = {
    guessesMade: number
    hasWon: boolean
    hasLost: boolean
    isTriviaRevealed?: boolean
}

type HintProps = {
    hint: string
    hintIndex: number
    guessesMade: number
    isGameOver: boolean
    isTriviaRevealed?: boolean
}

// classes during game play
const baseClasses = 'flex mx-auto h-6 text-lg no-text-select'
const hintClasses = `${baseClasses} justify-center leading-5 align-text-top pt-[2px]`
const placeholderClasses = `${baseClasses} border-solid border-b-[1px] border-emptyborder dark:border-emptyborder-dark border-x-[1px] bg-empty dark:bg-empty-dark`

const Hint = ({ hint, hintIndex, guessesMade, isGameOver, isTriviaRevealed }: HintProps) => {
    const store = useAppStore()
    const shouldHighlightHint = (!isGameOver && guessesMade === hintIndex + 1) || (isGameOver && !isTriviaRevealed && hintIndex >= guessesMade - 1)
    const shouldUnHighlightHint = !isGameOver && guessesMade === hintIndex + 2 && store.animatedHintStatus === AnimatedHintStatus.RUNNING

    return (
        <>
            {/* New hint being displayed */}
            {shouldHighlightHint && (isGameOver || store.animatedHintStatus !== AnimatedHintStatus.RUNNING) &&
                <Animated animationIn='zoomIn' animateOnMount animationOut='fadeIn' animationInDelay={0} isVisible={true}>
                    <span key={`hint-${hintIndex}`} className={`${hintClasses} text-white bg-absent border-absent dark:bg-absent-dark dark:border-absent-dark`}>
                        {hint}
                    </span>
                </Animated>
            }
            {/* Prior hint highlight fading out (dark mode) */}
            {shouldUnHighlightHint && store.isDarkMode &&
                <span key={`hint-${hintIndex}`} className={`${hintClasses} hint-unhighlight-dark text-white bg-absent border-absent dark:bg-absent-dark dark:border-absent-dark`}>
                    {hint}
                </span>
            }
            {/* Prior hint highlight fading out */}
            {shouldUnHighlightHint && !store.isDarkMode &&
                <span key={`hint-${hintIndex}`} className={`${hintClasses} hint-unhighlight text-white bg-absent border-absent dark:bg-absent-dark dark:border-absent-dark`}>
                    {hint}
                </span>
            }
             {/* All prior displayed hints */}
            {!shouldHighlightHint && !shouldUnHighlightHint && hintIndex >= 0 && (isGameOver || (guessesMade > hintIndex + 1)) &&
                <span key={`hint-${hintIndex}`} className={`${hintClasses} text-black dark:text-slate-300`}>
                    {hint}
                </span>
            }
            {/* First empty hint placeholder */}
            {hintIndex === -1 &&
                <span key={`hint-placeholder-${0-hintIndex}`} className={`${placeholderClasses} border-t-[1px]`}>
                    &nbsp;
                </span>
            }
            {/* Subsequent empty hint placeholders */}
            {hintIndex < -1 && 
                <span key={`hint-placeholder-${0-hintIndex}`} className={`${placeholderClasses} border-t-0`}>
                    &nbsp;
                </span>
            }
        </>
    )
}

// classes used at game start and end
const classes = classnames('flex justify-center h-6 text-lg items-center font-medium padding-10 no-text-select')

const TriviaHints = ({ guessesMade, hasWon, hasLost, isTriviaRevealed }: Props) => {
    const store = useAppStore()
    const puzzle = store.puzzle

    const gameOver = hasWon || hasLost
    const hsize = classNames('h-[111px]', gameOver ? 'iPhonePro:h-36' : 'iPhonePro:h-28')
    const hintAnimationFinished = store.animatedHintStatus === AnimatedHintStatus.FINISHED

    // Array of hints remaining; only clear next one once animation is complete
    const empties = Array.from(Array(Math.max(0, MAX_GUESSES - guessesMade - (hintAnimationFinished ? 1 : 0))))

    // Re-render when animation should be played
    useEffect(() => {}, [store.animatedHintStatus])

    return (
        <div className={hsize}>
            { /* Only show the puzzle topic when game starts */ }
            {(guessesMade === 0 || (guessesMade === 1 && !hintAnimationFinished && !gameOver)) &&
                 <div className='dark:text-slate-300'>
                 <MiniHeader title='Trivia Category' classes='text-lg font-semibold line-cell-large' />
                 <div className='flex justify-center items-center'>
                     <div className={`${classes} mx-1 text-black bg-unknown dark:bg-unknown-dark dark:text-white`}>
                         {puzzle.Topic}
                     </div>
                 </div>
                 <TriviaDetails startGame={true}/>
             </div>
            }
            { /* Show hints on subsequent guesses as long as there are more to show */ }
            {!gameOver && (guessesMade > 1 || (guessesMade === 1 && hintAnimationFinished)) &&
                <>
                    <MiniHeader title={`${puzzle.Topic}`} classes='text-lg font-semibold line-cell-large pb-[2px]' />
                    { guessesMade > 0 &&
                        <div>
                            {puzzle.Hints.map((hint: string, i: number) => (
                                <Hint key={uniqueId()} hint={hint} hintIndex={i} guessesMade={guessesMade} isGameOver={false} isTriviaRevealed={isTriviaRevealed} />
                            ))}
                        </div>
                    }
                    {empties.map((_, i) => (
                        <Hint key={uniqueId()} hint="a hint" hintIndex={-1 - i} guessesMade={guessesMade} isGameOver={false} />
                    ))}
                </>
            }
            {gameOver &&
                <div className='mt-2'>
                    <MiniHeader title={puzzle.Topic} classes='text-lg font-semibold line-cell-large pb-[2px]' />
                    <div>
                        {puzzle.Hints.map((hint: string, i: number) =>
                             <Hint key={uniqueId()} hint={hint} hintIndex={i} guessesMade={guessesMade} isGameOver={true} isTriviaRevealed={isTriviaRevealed} />
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(TriviaHints)
