import classnames from 'classnames';
import { sweepstakesDate, sweepstakesActivationDate } from '../../../lib/utils'
import CompletedRow from '../../board/CompletedRow'
import MiniHeader from '../../misc/MiniHeader'
import { NumberStatus } from '../../../lib/statuses'

type Props = {
    solution: string,
    topic: string,
    trivia: string,
    statusText: string
    didAgree: boolean,
    setDidAgree: (value: boolean) => void
}

const ConfirmationPage = ({solution, topic, trivia, statusText, didAgree, setDidAgree}: Props) => {
        
    const classes = classnames("text-sm text-gray-700 dark:text-gray-300 mb-2 no-text-select")

    const triviaClasses = 'flex justify-center items-center mb-2 mx-auto text-base w-full px-3 py-[4px] leading-5 align-text-top whitespace-pre-line no-text-select bg-unknown text-black dark:bg-unknown-dark dark:text-white'
   
    return (
        <div className={classes}>
            <div className='mx-auto px-5 pb-1'>
                <MiniHeader title={topic} />
            </div>
            <div className='text-center scale-110'>
                <CompletedRow key={solution} guess={solution} proximity={NumberStatus.CORRECT} isRevealing standAlone/>
                <div className='flex flex-col items-center mx-1 mt-3'>
                    <div className='flex justify-center items-center text-center text-white dark:text-slate-300 max-w-10 mb-2 h-[40px] align-text-top'>
                        <div className={`${triviaClasses}`}>
                            {trivia}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes} >
                <p className="mt-2 mb-2 leading-4">
                    By submitting this form, you are entering the {sweepstakesDate()} Wumber Trivia Sweepstakes with the Wumber displayed above.
                    It may take a few days to review your Wumber, during which time you'll be unable to make changes or submit another Wumber.
                    If your Wumber is accepted, it will be activated on {sweepstakesActivationDate()}.
                </p>
                <p className="mt-2 mb-4 leading-4">
                    If you don't win, you can repeatedly enter future sweepstakes with this Wumber simply by playing
                    and correctly guessing the daily Wumber.
                </p>
                <div className="table">
                    <input type="checkbox" id="agree" className="table-cell" checked={didAgree} onChange={(e) => setDidAgree(e.currentTarget.checked)}/>
                    <label htmlFor="agree" className="table-cell leading-4 pl-2">I am at least 18 years old and a legal resident of one of the 50 United States or the District of Columbia.</label>
                </div>
                <p className="mt-3 leading-4 text-sm">
                    {statusText}
                </p>
            </div>
        </div>
    )
}

export default ConfirmationPage