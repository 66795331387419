import { useState } from "react"
import CreateWumberModal from "../modals/CreateWumberModal"
import HistoryModal from "../modals/HistoryModal"
import { PrivacyModal } from "../modals/PrivacyModal"
import { FaqModal } from "../modals/FaqModal"
import { TermsModal } from "../modals/TermsModal"
import classNames from 'classnames'
import { LoginModal } from "../modals/LoginModal"
import { useAppStore } from "../../context/AppStoreContext"
import { observer } from "mobx-react-lite"
import { runInAction } from 'mobx'

// TODO LAUNCH: Remove this import
import ComingSoonModal from "../modals/ComingSoonModal";

declare global {
    interface Window {
        setToPlay(play : boolean): any;
    }
}

type Props = {
    show: boolean
    hasWon: boolean,
    onClose: () => void,
    setIsStatsModalOpen: (value: boolean) => void
}

const Menu = ({
    show,
    hasWon,
    onClose,
    setIsStatsModalOpen}: Props) => {
    const store = useAppStore()

    const classes = classNames(
        "absolute left-0 top-[1px] transition-transform duration-500 mt-12 pl-5 p-4 pr-5 gap-4 flex flex-col text-slate-600 dark:text-slate-300 h-screen menu",
        show ? "translate-x-0 menu-open" : "-translate-x-48 menu-closed",
        store.isDarkMode ? "menu-dark" : "menu"
    )

    const [openComingSoon, setOpenComingSoon] = useState(false)
    const [openCreateWumber, setOpenCreateWumber] = useState(false)
    const [openHistory, setOpenHistory] = useState(false)
    const [openFaq, setOpenFaq] = useState(false)
    const [openPrivacy, setOpenPrivacy] = useState(false)
    const [openTerms, setOpenTerms] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)

    const logoutVerbiage = store.userName ? `Logout ${store.userName}`: "Logout"
    const logout = () => {
        runInAction(() => {
            store.token = ""
            store.userName = ""
        })
    }

    return (
        <>
            <ol className={classes}>
                <li><button tabIndex={-1} onClick={() => {onClose(); window.setToPlay(false)}}>How to Play</button></li>
                <li><button tabIndex={-1} onClick={() => {onClose(); setIsStatsModalOpen(true)}}>Stats/Share</button></li>
                <li><button tabIndex={-1} onClick={() => {onClose(); setOpenHistory(x => !x)}}>Past Wumbers</button></li>
                <li className='-mb-5'>
                    <button tabIndex={-1} onClick={() => {onClose(); setOpenComingSoon(x => !x)}}>Create Your Wumber</button>
                    {/* <button tabIndex={-1} onClick={() => {onClose(); setOpenCreateWumber(x => !x)}}>Create Your Wumber</button>  */}
                    <div className='relative -top-10 left-10'>
                        <span className='text-[12px] ml-20 px-[4px] py-[1px] rounded-sm text-white bg-present dark:bg-present-dark shadow-lg'>beta</span>
                    </div>
                </li>
                <li><button tabIndex={-1} onClick={() => {onClose(); setOpenFaq(x => !x)}}>FAQs</button></li>
                <li><button tabIndex={-1} onClick={() => {onClose(); setOpenPrivacy(x => !x)}}>Privacy Policy</button></li>
                <li><button tabIndex={-1} onClick={() => {onClose(); setOpenTerms(x => !x)}}>Terms of Use</button></li>
                {
                    store.userName ? 
                    <li><button className="w-40 truncate" tabIndex={-1} onClick={() => {onClose(); logout()}}>{logoutVerbiage}</button></li> :
                    <li><button tabIndex={-1} onClick={() => {onClose(); setOpenLogin(x => !x)}}>Login</button></li>
                }
            </ol>
            {/* TODO LAUNCH restore CreateWumberModal and remove ComingSoonModal */}
            <ComingSoonModal isOpen={openComingSoon} handleClose={() => setOpenComingSoon(false)} />
            <CreateWumberModal isOpen={openCreateWumber} hasWon={hasWon} handleClose={() => setOpenCreateWumber(false)} />
            <HistoryModal isOpen={openHistory} handleClose={() => setOpenHistory(false)} />
            <FaqModal isOpen={openFaq} handleClose={() => setOpenFaq(false)} />
            <PrivacyModal isOpen={openPrivacy} handleClose={() => setOpenPrivacy(false)} />
            <TermsModal isOpen={openTerms} handleClose={() => setOpenTerms(false)} />
            <LoginModal isOpen={openLogin} handleClose={() => setOpenLogin(false)} />
        </>
    )
}
export default observer(Menu)
