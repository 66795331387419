import classnames from 'classnames'
import { Animated } from "react-animated-css"
import { HINT_DISPLAY_TIME_MS, HINT_DISPLAY_DELAY_TIME_MS } from '../../constants/settings'
import { useEffect, useState } from "react"
import { isFunction } from "lodash-es"

type Props = {
    hint: string
    shiftUp?: boolean
    hideAfter?: number
    onDone?: Function
    onNotDone?: Function
}

const AnimatedHint = ({ hint, shiftUp, hideAfter = HINT_DISPLAY_TIME_MS, onDone, onNotDone }: Props) => {
    const [show, setShow] = useState(true)

    const containerClasses = classnames(`absolute z-10 w-full ${shiftUp && '-mt-4'}`)
    const hintDivClasses = 'flex items-center align-middle justify-center text-center text-xs px-1 mx-[2px] leading-3 h-9 text-white bg-absent border-absent dark:bg-absent-dark dark:border-absent-dark'

    useEffect(() => {
        isFunction(onNotDone) && onNotDone()        // Run Reset function if specified

        if (hideAfter > 0) {
            const timer = setTimeout(() => {
                setShow(false)
                isFunction(onDone) && onDone()      // Run Set function if specified
            }, hideAfter)
            return () => clearTimeout(timer)
        }
    })

    // Click animated hint to dismiss
    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => { isFunction(onDone) && onDone() }

    const delayTime = hideAfter > 0 ? HINT_DISPLAY_DELAY_TIME_MS : 0

    return  (
        <div className={containerClasses}>
            <Animated className={hintDivClasses} animationIn='zoomIn' animationOut='zoomOut'
              animationInDelay={delayTime} animateOnMount isVisible={show}>
                <div onClick={handleClick}>{hint}</div>
            </Animated>
        </div>
    )
}

export default AnimatedHint
