import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { LOSS_ICON } from '../../constants/strings'
import { useAppStore } from '../../context/AppStoreContext'

type Props = {
    index: string
    size: number
    label: string
    currentDayStatRow: boolean
}

const Progress = ({ index, size, label, currentDayStatRow }: Props) => {
    const store = useAppStore()
    const isHighContrast = store.highContrast
    const rowClass = classNames(
        'text-xs font-medium text-blue-100 text-center p-0.5',
        {
            'bg-correct': currentDayStatRow && index !== LOSS_ICON && !isHighContrast,
            'bg-correct-hc': currentDayStatRow && index !== LOSS_ICON && isHighContrast,
            'bg-error': currentDayStatRow && index === LOSS_ICON && !isHighContrast,
            'bg-error-hc': currentDayStatRow && index === LOSS_ICON && isHighContrast,
            'bg-gray-600': !currentDayStatRow
        }
    )
    return (
        <div className="flex justify-left ml-1 mr-6 my-1">
            <div className="items-center justify-center w-2 font-bold">{index}</div>
            <div className="w-full ml-2">
                <div style={{ width: `${Math.max(size, 6)}%` }} className={rowClass}>
                    {label}
                </div>
            </div>
        </div>
    )
}

export default observer(Progress)
