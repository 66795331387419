import classnames from 'classnames'

type Props = {
    topic: string,
    setTopic: (value: string) => void
    trivia: string,
    setTrivia: (value: string) => void
}

const TriviaPage = ({topic, setTopic, trivia, setTrivia}: Props) => {
    var classes = classnames("text-gray-700 dark:text-gray-300 mb-2 leading-4")

    return (
        <>
            <div className={`${classes} text-sm pb-2`}>
                Topic:&nbsp;
                <select name="topic" id="topic" className='text-gray-700 dark:text-black mx-auto py-1' value={topic}
                    tabIndex={1} onChange={(e) => setTopic(e.currentTarget.value)}>
                    <option value="">Select a topic...</option>
                    <option value="Architecture">Architecture</option>
                    <option value="Books">Books</option>
                    <option value="Finance">Finance</option>
                    <option value="Fun & Games">Fun & Games</option>
                    <option value="Geography">Geography</option>
                    <option value="Government">Government</option>
                    <option value="History">History</option>
                    <option value="Math">Math</option>
                    <option value="Measurement">Measurement</option>
                    <option value="Measurement">Medicine</option>
                    <option value="Movies">Movies</option>
                    <option value="Music">Music</option>
                    <option value="Pop Culture">Pop Culture</option>
                    <option value="Science">Science</option>
                    <option value="Sports">Sports</option>
                    <option value="Technology">Technology</option>
                    <option value="Technology">Television</option>
                </select>
            </div>
            <div className={classes}>
                <p className="mb-3 leading-4 text-sm">
                    Enter your Wumber's trivia. This must be a verifiable and fairly enduring fact.
                </p>
                <p className="mb-5 leading-4 text-sm">
                    Your Wumber will not be accepted if the trivia is untrue, offensive/controversial, ambiguous, vague, personal (e.g. your birthday), transient (e.g. career points scored by an active player), or arbitrary (e.g. a dishwasher part number).
                </p>
                <div className={classes}>
                    <textarea id="triviaTextArea" className='dark:text-black text-base py-1' rows={2} cols={33} placeholder="Your Wumber's Trivia"
                        tabIndex={0} autoFocus maxLength={100} value={trivia} onChange={(e) => setTrivia(e.currentTarget.value)} />
                </div>
            </div>
            <script>
                document.getElementById("triviaTextArea").focus();
            </script>
        </>
    )
}

export default TriviaPage