import { isEmpty } from 'lodash-es'
import { runInAction } from 'mobx'
import { useLayoutEffect, useState, useContext } from 'react'
import { DigitStatus, WaitStatus } from '../../lib/statuses'
import { isGuessComplete } from '../../lib/utils'
import { eraseAtIndex, indexOfNextOpenCell, isEmptyOrUndefinedString } from '../../lib/utils'
import PuzzleContext from '../../context/PuzzleContext.js'
import AnimatedHint from './AnimatedHint'
import Tile from './Tile'
import { useAppStore } from '../../context/AppStoreContext'
import { AnimatedHintStatus } from '../../state'

type Props = {
    guess: string[]
    setCurrentGuess?: (guess: string[]) => void
    setCursorIndex?: (index: number) => void
    cursorIndex: number
    priorGuess: string
    className: string
    setCurrentRowClass: (className: string) => void
    shouldShowHint: boolean
    statuses: DigitStatus[]      // Only set if prefill digit option is on
    numGuesses: number
    isWaiting: boolean
}

const CurrentRow = ({ guess, setCurrentGuess = () => {}, setCursorIndex = () => {}, setCurrentRowClass = () => {},
    shouldShowHint, cursorIndex, priorGuess, className, statuses, numGuesses, isWaiting }: Props) => {
//console.log("CurrentRow: ", guess)
    const store = useAppStore()
    const [showHint, setShowHint] = useState(shouldShowHint)
    var digitClasses = `relative flex justify-center ${className}`

    let isGuessEmpty = true
    statuses.forEach((status, i) => {
        guess[i] = status === DigitStatus.CORRECT ? priorGuess[i] : guess[i]
        if (isGuessEmpty && status !== DigitStatus.CORRECT && !isEmptyOrUndefinedString(guess[i])) {
            // TODO: Below line causes "Warning: Cannot update a component (`TriviaHints`) while rendering a different component (`CurrentRow`)"
            runInAction(() => {if (store.animatedHintStatus === AnimatedHintStatus.RUNNING) store.animatedHintStatus = AnimatedHintStatus.FINISHED})  // Any input immediately hides AnimatedHint
            isGuessEmpty = false
        }
    })

    useLayoutEffect(() => {
        const openCellIndex = indexOfNextOpenCell(guess, priorGuess.length, cursorIndex)
        if (openCellIndex !== cursorIndex)
            setCursorIndex(openCellIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guess, priorGuess.length])

    const handleClick = (index: number) => {
        if (statuses[index] !== DigitStatus.CORRECT) {
            setCurrentGuess(eraseAtIndex(statuses, guess, index))
            setCurrentRowClass('')
            digitClasses = 'relative flex justify-center'
            setCursorIndex(index)
            setShowHint(false)
        }
    }

    const puzzle = useContext(PuzzleContext)

    return (
        <div className='relative -z-10'>
            {isGuessEmpty && showHint && puzzle.Hints.length >= numGuesses &&
                <AnimatedHint hint={puzzle.Hints[numGuesses - 1]}
                    onDone={() => {
                        setShowHint(false)
                        runInAction(() => store.animatedHintStatus = AnimatedHintStatus.FINISHED)}}
                    onNotDone={() => {
                        runInAction(() => store.animatedHintStatus = AnimatedHintStatus.RUNNING)}}
                />
            }
            <div className={digitClasses}>
                {guess.map((digit, i) => (
                    <div key={i}>
                        {!isEmpty(guess[i]) &&
                            <Tile key={i}
                                value={digit}
                                waitStatus={isWaiting && !isGuessComplete(guess) ? WaitStatus.CURRENTROW : WaitStatus.CURRENTROW_NOTWAITING}
                                isCursor={i === cursorIndex}
                                status={statuses[i] === DigitStatus.CORRECT ? DigitStatus.CORRECT : undefined}
                                handleClick={() => handleClick(i)}
                            />
                        }
                        {isEmpty(guess[i]) &&
                            <Tile key={i} isCursor={i === cursorIndex} handleClick={() => setCursorIndex(i)}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CurrentRow
