import { CogIcon } from '@heroicons/react/outline'
import Hamburger from 'hamburger-react'
import { useState } from 'react'
import Menu from './Menu'
import LogoRow from '../board/LogoRow'
import OutsideClickHandler from 'react-outside-click-handler'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../../context/AppStoreContext'

type Props = {
    hasWon: boolean
    setIsStatsModalOpen: (value: boolean) => void
    setIsSettingsModalOpen: (value: boolean) => void
}

const Navbar = ({
    hasWon,
    setIsStatsModalOpen,
    setIsSettingsModalOpen,
}: Props) => {
    const store = useAppStore()
    const isDarkMode = store.isDarkMode;
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    return (
        <OutsideClickHandler onOutsideClick={() => setHamburgerOpen(false)}>
            <div className="navbar dark:border-b-2 border-slate-500">
                <div className="navbar-content">
                    <div className="flex">
                        <Hamburger size={22} color={isDarkMode ? "white" : "black"} toggled={hamburgerOpen} onToggle={() => setHamburgerOpen(!hamburgerOpen)} />
                    </div>
                    <div className='app-logo' style={{ position: "relative", left: "18px" }}>
                        <LogoRow />
                    </div>
                    <div className="flex pr-4">
                        <CogIcon className="h-6 w-6 cursor-pointer stroke-black dark:stroke-white" onClick={() => setIsSettingsModalOpen(true)} />
                    </div>
                </div>
                <hr />
                <Menu show={hamburgerOpen} hasWon={hasWon} onClose={() => setHamburgerOpen(false)} setIsStatsModalOpen={setIsStatsModalOpen} />
            </div>
        </OutsideClickHandler>
)}

export default observer(Navbar)
