import { DigitStatus, getGuessStatuses, NumberStatus, WaitStatus } from '../../lib/statuses'
import Tile from './Tile'
import { DirectionArrow, DirectionArrowType } from './DirectionArrow'
import { observer } from 'mobx-react'
import { useAppStore } from '../../context/AppStoreContext'
import { MAX_DIGITS } from '../../constants/settings'
import { uniqueId } from 'lodash-es'

type Props = {
    guess: string
    proximity: NumberStatus
    isRevealing?: boolean
    isWaiting?: boolean
    directionArrowType?: DirectionArrowType
    showSolution?: boolean
    standAlone?: boolean
}

const CompletedRow = ({ guess, isRevealing, isWaiting = false, proximity, showSolution, directionArrowType = DirectionArrowType.NONE, standAlone = false }: Props) => {   
    const store = useAppStore()
    const solution = store.solution

    const isLower = proximity === NumberStatus.LOWER;
    const isHigher = proximity === NumberStatus.HIGHER;
    const isGameOver = !isLower && !isHigher;
    const displayArrows = directionArrowType !== DirectionArrowType.NONE && !isWaiting && !isGameOver
    const shouldAnimate = directionArrowType === DirectionArrowType.ANIMATED && !isWaiting &&!isRevealing
    const statuses = proximity === NumberStatus.CORRECT ? Array(MAX_DIGITS).fill(DigitStatus.CORRECT)
        : proximity === NumberStatus.WRONG && showSolution ? Array(MAX_DIGITS).fill(DigitStatus.ERROR)
        : getGuessStatuses(guess, solution)   // TODO: try to not recalculate on every keystroke - move into GameContext

    const priorGuessStatusIndex = !standAlone && store.prefillCorrectDigits ? store.guesses.indexOf(guess) : -1
    const priorGuessStatuses = priorGuessStatusIndex > 0 ? getGuessStatuses(store.guesses[priorGuessStatusIndex - 1], solution) : undefined
    
    //console.log("CompletedRow rendering for "+guess+" isWaiting="+isWaiting+", isRevealing="+isRevealing)
    //if (directionArrowType === DirectionArrowType.ANIMATED)
    //     console.log("shouldAnimate="+shouldAnimate)
    
    // If the number is wrong (game over with loss), then display the solution instead
    if (proximity === NumberStatus.WRONG && showSolution)
        guess = solution
    const digits = guess.split('')

    return (
        <div className="flex flex-col justify-center z-50">
            <div className='flex justify-center relative z-50'>
                {digits.map((_, i) => (
                    <div key={uniqueId()} className='relative flex justify-around'>
                        {displayArrows && i === 0 &&
                            <DirectionArrow animate={shouldAnimate} proximity={proximity} />
                        }
                        <Tile key={i} value={guess[i]}
                            status={(proximity === NumberStatus.WRONG && statuses[i] !== DigitStatus.CORRECT)
                                ? DigitStatus.ERROR
                                : statuses[i]}
                            position={i} isRevealing={isRevealing} waitStatus={isWaiting ? WaitStatus.COMPLETEDROW : WaitStatus.NONE}
                            wasPrefilled={priorGuessStatuses && priorGuessStatuses[i] === DigitStatus.CORRECT}
                        />
                        {displayArrows && i === digits.length - 1 &&
                            <DirectionArrow animate={shouldAnimate} proximity={proximity} isLeft={false} />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default observer(CompletedRow)
