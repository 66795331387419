export const MAX_DIGITS = 6
export const MAX_GUESSES = 5

export const TILE_REVEAL_TIME_MS = 120

// Keyboard settings
export const NUMBER_KEY_WIDTH = 42
export const MAX_KEYBOARD_WIDTH = 500

export const ALERT_TIME_MS = 4500
export const HINT_DISPLAY_TIME_MS = 5000
export const HINT_DISPLAY_DELAY_TIME_MS = 2500      // 1700 + bulge.animation-delay
export const ROW_SLIDE_DELAY_TIME_MS = 1500
export const END_GAME_STATS_DELAY_TIME_MS = 4000
export const FIRST_GUESS_ANIMATION_TIME_MS = 4000

// Jan 1, 2024 Game Epoch - have to set to one day prior to account for zero-based indexing
export const gameEpoch = new Date(new Date('2023-12-31T00:00:00').setHours(0, 0, 0, 0))

export const gameTimeZone = "America/New_York"

// FIXME: Don't know why the below line doesn't work locally using .env file
export const baseUrl = process.env.REACT_APP_WUMBER_API_URL
//export const baseUrl = 'https://localhost:5001'    // TODO: don't publish!
