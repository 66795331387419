import Tile from './Tile'
import AnimatedHint from './AnimatedHint'

type Props = {
    numCells: number
    showStartHint?: boolean
    invisible?: boolean
}

const EmptyRow = ({numCells, showStartHint, invisible = false} : Props) => {
    const emptyCells = Array.from(Array(numCells))
const hint = numCells <= 2 ? 'Tap above to guess' : numCells === 3 ? 'Tap skill-stop to set 1st guess' : 'Tap skill-stop above to set 1st guess'

  return (
    <div className="flex justify-center z-0">
        {emptyCells.map((_, i) => (
            <Tile key={i} invisible={invisible} />
        ))}
        {showStartHint &&
            <AnimatedHint hint={hint} shiftUp={true} hideAfter={-1} />
        }
    </div>
  )
}

export default EmptyRow
