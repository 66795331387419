import classnames from 'classnames'

type Props = {
    settingName: string
    flag: boolean
    handleFlag: Function
    description?: string
    isHighContrastMode: boolean
}

const SettingsToggle = ({
    settingName,
    flag,
    handleFlag,
    description,
    isHighContrastMode
}: Props) => {
  const toggleHolder = classnames(
    'w-14 h-8 flex shrink-0 items-center rounded-full p-1 duration-300 ease-in-out cursor-pointer',
      {
        'bg-gray-300': !flag,
        'bg-correct-hc': flag && isHighContrastMode,
        'bg-correct dark:bg-correct-dark': flag && !isHighContrastMode,
    }
  )
  const toggleButton = classnames(
    'bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer',
    {
      'translate-x-6': flag,
    }
  )

  return (
    <>
      <div className="flex justify-between py-3">
        <div className="text-slate-600 dark:text-slate-300 text-left">
          <p className="leading-none">{settingName}</p>
          {description && (
            <p className="text-xs mt-1 mr-2 text-slate-600 dark:text-slate-300">
              {description}
            </p>
          )}
        </div>
        <div className={toggleHolder} onClick={() => handleFlag(!flag)}>
          <div className={toggleButton} />
        </div>
      </div>
    </>
  )
}

export default SettingsToggle
