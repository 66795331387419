import { v4 as uuidv4 } from 'uuid'
import { PuzzleData } from './lib/PuzzleData';
import { autorun, makeAutoObservable, set, toJS } from 'mobx'
import { gameEpoch } from './constants/settings'
import { GameStats } from './lib/stats';
import { fill } from 'lodash-es';
import { MAX_GUESSES } from './constants/settings';
import { decodeSolution } from './lib/utils';

export function autoSave(_this: any, name: string) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}

export enum AnimatedHintStatus {
    NOTSTARTED = 0,
    RUNNING = 1,
    FINISHED = 2
  }

export class AppState {
	guesses: string[]
	puzzle: PuzzleData
	playerId: string
	theme: string
	highContrast: boolean
	prefillCorrectDigits: boolean
	leftyConfiguration: boolean
	animateLogo: boolean
	immediateTriviaReveal: boolean
	triviaRevealed : boolean
    statsShareDisplayed : boolean
	stats: GameStats
	token: string
	userName: string
	animatedHintStatus: AnimatedHintStatus
    newRowAnimationFinished: boolean

	constructor() {
		const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
		makeAutoObservable(this)
		this.guesses = []
		this.puzzle = {
			Date: gameEpoch,
			Puzzle: "",
			Topic: "",
			Hints: [],
			Trivia: "",
			VerifyUrl: "",
			UserName: "",
			UserAvatar: "",
			UserLocation: "",
			ActivePuzzleCount: 0,
			NumPlays: 0,
			NumWins: 0,
			AmountWon: 0,
		}
		this.playerId = uuidv4()
		this.theme = prefersDarkMode ? 'dark' : 'light'
		this.highContrast = false
		this.prefillCorrectDigits = false
		this.leftyConfiguration = false
		this.animateLogo = true
		this.immediateTriviaReveal = false
		this.triviaRevealed = false
        this.statsShareDisplayed = false
		this.stats = {
			gamesPlayed: 0,
			winDistribution: fill(Array(MAX_GUESSES), 0),
			currentStreak: 0,
			bestStreak: 0
		}
		this.token = ""
		this.userName = ""
		this.animatedHintStatus = AnimatedHintStatus.NOTSTARTED
        this.newRowAnimationFinished = true
		autoSave(this, 'appStore')
	}
	get isDarkMode() {
    return this.theme === 'dark'
  }
	get areNoMoreGuesses() {
    return this?.guesses?.length === MAX_GUESSES
  }
	get solution() {
		return decodeSolution(this?.puzzle?.Puzzle || '')
	}
}
