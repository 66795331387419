import { useCallback, useEffect, useRef, useState } from "react"
import classnames from 'classnames'
import useInterval from '../../hooks/useInterval'
import { MAX_DIGITS, FIRST_GUESS_ANIMATION_TIME_MS } from '../../constants/settings'
import { observer } from "mobx-react"
import { runInAction } from 'mobx'
import { useAppStore } from "../../context/AppStoreContext"
import useResizeObserver from "use-resize-observer"
import { DirectionArrow } from './DirectionArrow'
import { NumberStatus } from '../../lib/statuses'

type Props = {
    onEnter: () => void
    className?: string
    numSolutionDigits: number
    setCurrentGuess: (guesses: string[]) => void
}

const FirstRow = ({
    onEnter,
    className,
    numSolutionDigits,
    setCurrentGuess
}: Props) => {
    const store = useAppStore()
    const isHighContrast = store.highContrast
    const rateStep = 15
    const max = Number('9'.repeat(numSolutionDigits))
    const speedFactor = MAX_DIGITS / numSolutionDigits
    const maxDelta = Math.ceil(max * speedFactor / 50)
    const minDelta = Math.ceil(max * speedFactor / 1000)

    const [guess, setGuess] = useState(0)
    const [containerWidth, setContainerWidth] = useState(0)
    const [rate, setRate] = useState<number | null>(rateStep)
    const [effectiveMaxDelta, setEffectiveMaxDelta] = useState(maxDelta)
    const [positive, setPositive] = useState(true)
    const ref = useRef<HTMLDivElement>(null)

    function getRandomStep() {
        return Math.floor(minDelta + Math.random() * (effectiveMaxDelta - minDelta))
    }

    const guessRangeEdgeTolerance = 200000 / (10 ** (MAX_DIGITS - numSolutionDigits))
    const guessRangeLowerEdgeTolerance = guessRangeEdgeTolerance
    const guessRangeHigherEdgeTolerance = 10 ** numSolutionDigits - guessRangeEdgeTolerance

    // Handle component resize and set containerWidth
    useResizeObserver<HTMLDivElement>({
        onResize: () => ref.current && setContainerWidth(ref.current.clientWidth),
        ref
    })

    // Handle the init of containerWidth
    useEffect(() => {
        ref?.current && setContainerWidth(ref.current.clientWidth)
    }, [])

    useInterval(() => {
        if (rate !== null) {
            const change = getRandomStep()
            setGuess(current => {
                var newGuess = positive ? current + change : current - change
                if (newGuess > max) {
                    newGuess = 2 * max - newGuess
                    setPositive(false)
                } else if (newGuess < 0) {
                    newGuess = 0 - newGuess
                    setPositive(true)
                }

                // Slow near edges
                if (newGuess < guessRangeLowerEdgeTolerance) {
                    const edgeProximity = newGuess
                    setEffectiveMaxDelta(maxDelta / (2 - edgeProximity / guessRangeEdgeTolerance))
                } else if (newGuess > guessRangeHigherEdgeTolerance) {
                    const edgeProximity = max - newGuess
                    setEffectiveMaxDelta(maxDelta / (2 - edgeProximity / guessRangeEdgeTolerance))
                } else
                    setEffectiveMaxDelta(maxDelta)

                return newGuess
            })
        }
    }, rate)

    const paddedGuess = guess.toString().padStart(numSolutionDigits, '0')

    useEffect(() => {
        if (rate === null) {
            // Pause for animation
            let timer1 = setTimeout(() => {
                onEnter()
            }, FIRST_GUESS_ANIMATION_TIME_MS)

            return () => {
                clearTimeout(timer1)
            }
        }
    }, [rate, paddedGuess, onEnter])

    const stop = useCallback(() => {
        if (rate !== null) {
            setRate(null)
            setCurrentGuess(paddedGuess.split(''))
        }
        window.removeEventListener('keyup', stop)
    }, [setCurrentGuess, paddedGuess, rate, setRate])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                runInAction(() => store.newRowAnimationFinished = false)
                stop()
            }
        }
        window.addEventListener('keyup', listener)
        return () => {
            window.removeEventListener('keyup', listener)
        }
    }, [store, stop])

    const xPos = (guess / max * (containerWidth - 22)) - 6  // tailwind w-7 = 28px

    const classes = `flex relative justify-center no-text-select ${className} h-9`
    const containerClasses = classnames(
        'flex relative w-full h-full mx-[2px] text-2xl border-solid border-2 font-bold justify-center',
        'text-black bg-empty dark:text-white dark:bg-empty-dark'
    )

    return (
        <>
            {/* Display final position of skill-stop after click */}
            {rate === null &&
                <div ref={ref} className={classes}>
                    <button type="button" onClick={stop} className={`spread-container-button ${containerClasses}`}>
                        <div className="spread-container leading-none flex">
                            {paddedGuess.split('').map((x, i) => <div key={i} className="spread">{x}</div>)}
                        </div>
                    </button>
                </div>
            }
            {/* Skill-stop moving before first guess */}
            {rate !== null &&
                <>
                    <DirectionArrow animate={true} proximity={NumberStatus.CORRECT} isLeft={true} />
                    <div ref={ref} className={classes}>
                        <button type="button" onClick={stop} className={`border-black dark:border-slate-100 ${containerClasses}`}>
                            <div className={classnames('absolute h-full w-7 bg-gradient-to-r from-transparent to-transparent',
                                `${isHighContrast ? 'via-present-hc' : 'via-present dark:via-present-dark'}`
                                )} style={{ left: `${xPos}px` }} />
                            <div className="flex flex-row align-text-top">
                                {paddedGuess.split('').map((x, i) => <div key={i} className="w-[22px]">{x}</div>)}
                            </div>
                        </button>
                    </div>

                    <DirectionArrow animate={true} proximity={NumberStatus.CORRECT} isLeft={false} />
                </>
            }
        </>
    )
}

export default observer(FirstRow)
