import { ReactNode } from 'react'
import classnames from 'classnames'
import { DigitStatus } from '../../lib/statuses'
import { TILE_REVEAL_TIME_MS, NUMBER_KEY_WIDTH } from '../../constants/settings'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../../context/AppStoreContext'
import { get } from 'lodash-es'

type Props = {
    children?: ReactNode
    value: string
    width?: number
    status?: DigitStatus
    onClick: (value: string) => void
    isRevealing?: boolean
    keyClassName: string
}

const Key = ({
    children,
    status,
    width = NUMBER_KEY_WIDTH,
    value,
    onClick,
    isRevealing,
    keyClassName,
}: Props) => {
    const store = useAppStore()
    const isHighContrast = store.highContrast
    const numDigits = get(store, 'puzzle.Solution.length')
    const classes = classnames(
        'shrink flex-1 h-16 xs:h-12 items-center justify-center rounded mx-key cursor-pointer select-none',
        {
            'transition ease-in-out': isRevealing,
            'bg-absent dark:bg-absent-dark text-white disabled': status === DigitStatus.ABSENT,
            'bg-present-hc text-white': status === DigitStatus.PRESENT && isHighContrast,
            'bg-present dark:bg-present-dark text-white': status === DigitStatus.PRESENT && !isHighContrast,
            'bg-correct-hc text-white': status === DigitStatus.CORRECT && isHighContrast,
            'bg-correct dark:bg-correct-dark text-white': status === DigitStatus.CORRECT && !isHighContrast,
            'bg-unknown dark:bg-unknown-dark text-black dark:text-white': status === undefined || status === DigitStatus.ERROR
        }
    )

    const keyDelayMs = TILE_REVEAL_TIME_MS * numDigits
    const styles = {
        transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
        width: width,
        display: 'flex'
    }

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        // Don't allow clicking of absent digits
        if (status !== DigitStatus.ABSENT) {
            onClick(value)
            event.currentTarget.blur()
        }
    }

    return (
        <button style={styles} className={`${keyClassName} ${classes}`} onClick={handleClick}>
            {children || value}
        </button>
    )
}

export default observer(Key)
