import { gameEpoch, gameTimeZone } from '../constants/settings'
import { getGuessStatuses } from './statuses'
import { PuzzleData } from './PuzzleData'
import moment from 'moment-timezone'

import { NOT_CONTAINED_MESSAGE,
    MUST_BE_HIGHER_MESSAGE,
    MUST_BE_LOWER_MESSAGE,
    MUST_BE_BETWEEN_MESSAGE,
    CORRECT_DIGIT_MISSING_MESSAGE,
    FIRST_DIGIT_MISSING_MESSAGE,
    LAST_DIGIT_MISSING_MESSAGE
} from '../constants/strings'

const puzzleDefault: PuzzleData = { 'Date': new Date(), 'Puzzle': '', 'Topic': '', 'Hints': [''],
    'Trivia': '', 'VerifyUrl': '', 'UserName': '', 'UserAvatar': '', 'UserLocation': '', ActivePuzzleCount: 0, NumPlays: 0, NumWins: 0, AmountWon: 0 }

const getDateString = (date: Date): string => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

async function getPastPuzzlesAsync(): Promise<PuzzleData[]> {
    let pastPuzzles = new Array<PuzzleData>()
    const today = new Date(new Date().setHours(0, 0, 0, 0))
    var yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    var oldestDate = new Date(today)
    oldestDate.setDate(oldestDate.getDate() - 7)
    for (var d = yesterday; d >= oldestDate; d.setDate(d.getDate() - 1)) {
        const puzzleFilePath = `/PastWumbers/${getDateString(d)}.json`
        const puzzleData = await readPuzzleFileAsync(puzzleFilePath)
        if (puzzleData.Date === today || puzzleData === puzzleDefault)
            continue
        if (typeof(puzzleData.UserAvatar) === 'undefined' || puzzleData.UserAvatar === '')
            puzzleData.UserAvatar = "SYSTEM.png"
        puzzleData.Trivia = puzzleData.Trivia.replaceAll('\\n', '\n')       // Remove encoding of escape char
        pastPuzzles.push(puzzleData)
    }

    return pastPuzzles
}

async function readPuzzleFileAsync(fileName: string): Promise<PuzzleData> {
    const response = await fetch(fileName)
    let jsonPuzzleData

    try {
        jsonPuzzleData = await response.json()
    } catch {
        return puzzleDefault
    }
    const puzzleData = jsonPuzzleData as PuzzleData
    puzzleData.Date = new Date(fileName.split('.')[0].slice(-10) + 'T00:00:00')
    return puzzleData
}

export const sortGuesses = (guesses: string[]) : string[] => {
    let sortedGuesses = new Array<string>()
    if (typeof guesses === 'undefined' || guesses.length === 0)
        return sortedGuesses

    let numberGuesses = new Array<number>()
    guesses.map((guess) => numberGuesses.push(Number(guess)))
    numberGuesses.sort((a, b) => b - a)

    // Restore any leading zeros lost in conversion
    const numDigits = guesses[0].length
    numberGuesses.map((guess) => sortedGuesses.push(guess.toString().padStart(numDigits, '0')))

    return sortedGuesses
}

const getClosetLowGuess = (sortedGuesses: string[], playerGuess: string) : number => {
    var closestGuess = -1
    for (var i = 0; i < sortedGuesses.length; i++) {
        if (Number(sortedGuesses[i]) < Number(playerGuess)) {
            closestGuess = Number(sortedGuesses[i])
            break
        }
    }

    return closestGuess
}

const getClosetHighGuess = (sortedGuesses: string[], playerGuess: string) : number => {
    var closestGuess = Number.MAX_SAFE_INTEGER
    for (var i = 0; i < sortedGuesses.length; i++) {
        if (Number(sortedGuesses[i]) > Number(playerGuess)) {
            closestGuess = Number(sortedGuesses[i])
        }
    }

    return closestGuess
}

const pad = (num: number, size: number) : string => {
    var s = "000000" + num;
    return s.slice(0 - size)
}

// build a set of previously revealed digits - present and correct
// check if all revealed instances of a digit are used (e.g. two 7's)
// check if new guess is in the right direction from last guess (higher/lower)
export const findFirstClueNotRespected = (currentGuess: string[], priorGuesses: string[], solution: string) => {
    if (priorGuesses.length < 1) {
        return false
    }

    const sortedGuesses = sortGuesses(priorGuesses)
    const mustBeHigherThan = getClosetLowGuess(sortedGuesses, solution)
    const mustBeLowerThan = getClosetHighGuess(sortedGuesses, solution)

    const digitsRequiredArray = new Array<string>()
    const priorGuess = priorGuesses[priorGuesses.length - 1]
    const statuses = getGuessStatuses(priorGuess, solution)
    const splitPriorGuess = priorGuess.split('')
    const numDigits = currentGuess.length
    const padHigher = pad(mustBeHigherThan, numDigits)
    const padLower = pad(mustBeLowerThan, numDigits)

    const between = (mustBeHigherThan !== -1 && mustBeLowerThan !== Number.MAX_SAFE_INTEGER)

    // Enforce higher/lower range
    if (Number(currentGuess.join('')) <= mustBeHigherThan)
        return between ? MUST_BE_BETWEEN_MESSAGE(padHigher, padLower)
            : MUST_BE_HIGHER_MESSAGE(padHigher)
    if (Number(currentGuess.join('')) >= mustBeLowerThan)
        return between ? MUST_BE_BETWEEN_MESSAGE(padHigher, padLower)
            : MUST_BE_LOWER_MESSAGE(padLower)

    for (let i = 0; i < numDigits; i++) {
        if (statuses[i] === 'present' || statuses[i] === 'correct') {
            let thisDigit = splitPriorGuess[i]
            if (statuses[i] === 'correct' && thisDigit !== currentGuess[i])
            {
                return i === 0 ? FIRST_DIGIT_MISSING_MESSAGE(thisDigit)
                    : (i === numDigits - 1) ? LAST_DIGIT_MISSING_MESSAGE(thisDigit)
                    : CORRECT_DIGIT_MISSING_MESSAGE(thisDigit, i)
            }
            digitsRequiredArray.push(splitPriorGuess[i])
        }
    }

    // check for the first unused digit, taking duplicate digits into account
    let n: number
    for (const digit of currentGuess) {
        n = digitsRequiredArray.indexOf(digit)
        if (n !== -1) {
            digitsRequiredArray.splice(n, 1)
        }
    }

    if (digitsRequiredArray.length > 0) {
        return NOT_CONTAINED_MESSAGE(digitsRequiredArray)
  }
  return false
}

//Load specific puzzle instead of all puzzles. Index of 0 starts at yesterday
export async function getHistoricPuzzleAsync(index: number): Promise<PuzzleData | null> {
    const today = new Date(new Date().setHours(0, 0, 0, 0))
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const targetDate = new Date(yesterday)
    targetDate.setDate(targetDate.getDate() - index)

    const puzzleFilePath = `/PastWumbers/${getDateString(targetDate)}.json`
    const puzzleData = await readPuzzleFileAsync(puzzleFilePath)

    if (puzzleData.Date === today || puzzleData === puzzleDefault)
        return null

    if (typeof(puzzleData.UserAvatar) === 'undefined' || puzzleData.UserAvatar === '')
            puzzleData.UserAvatar = "SYSTEM.png"

    puzzleData.Trivia = puzzleData.Trivia.replaceAll('\\n', '\n')       // Remove encoding of escape char

    return puzzleData
}

export const getHistoricPuzzlesAsync = async () => {
    var jsonData = await getPastPuzzlesAsync()
    return jsonData.sort((a, b) => b.Date.getTime() - a.Date.getTime())
}

const epochMs = gameEpoch.valueOf()
const now = Date.now()
export const msInDay = 86400000
export const gameNumber = Math.floor(Math.max(now - epochMs, 0) / msInDay) + 1

const getTimeDifferenceFromEasternTime = () => {
  
    const localTime: moment.Moment = moment()
    const localTimeZoneOffset: number = localTime.utcOffset()
    const easternTimeZoneOffset: number = moment.tz(gameTimeZone).utcOffset()
    const timeDifferenceInMinutes: number = localTimeZoneOffset - easternTimeZoneOffset
  
    return timeDifferenceInMinutes
}

// Garbage needed for JavaScript to properly handle gameplay in forward timezones...
declare global {
    interface Date {
        addMinutes(m: number): Date
    }
}
  
// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (m: number): Date {
    this.setMinutes(this.getMinutes() + m)
    return this
}

const today: Date = new Date()
const midnight: Date = new Date(now)
midnight.setHours(0, 0, 0, 0)
const tzOffsetFromEastern = getTimeDifferenceFromEasternTime()
const minutesAfterMidnight: number = Math.floor((today.getTime() - midnight.getTime()) / (1000 * 60))

export const isAheadOfTodaysPuzzle = tzOffsetFromEastern > 0 && minutesAfterMidnight < tzOffsetFromEastern

const tomorrow = new Date(today.setDate(today.getDate() + 1))
export const nextPuzzleAvailable = isAheadOfTodaysPuzzle ?
                                        midnight.addMinutes(tzOffsetFromEastern).getTime() :
                                        ((tomorrow.setHours(0,0,0,0)) + 1).valueOf() + 60000   // 12:01am

export async function getTodaysPuzzleAsync() : Promise<PuzzleData> {
    const today = new Date(new Date().setHours(0, 0, 0, 0))
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const puzzleDate = isAheadOfTodaysPuzzle ? yesterday : today
    const filePath = `${getDateString(puzzleDate)}.json`
    const puzzleData = await readPuzzleFileAsync(filePath)
    puzzleData.Trivia = puzzleData.Trivia.replaceAll('\\n', '\n')       // Remove encoding of escape char
    if (typeof(puzzleData.UserAvatar) === 'undefined' || puzzleData.UserAvatar === '')
        puzzleData.UserAvatar = "SYSTEM.png"
    return puzzleData
}