import { useEffect, useRef } from "react"

declare global { 
  interface Window {
      google: any;
  }
}

type Props = {
    handleGoogleResponse: (response: any) => void
}

const GoogleButton = ({handleGoogleResponse}: Props) => {
    const divRef = useRef<HTMLDivElement>(null)
  
    useEffect(() => {
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return
      }
  
      try {
        window.google.accounts.id.initialize({
          client_id: "440421666933-luo4omum2o57ng9e7bf9uuhu9kb1t1j0.apps.googleusercontent.com",
          callback: async (res: any) => {
            handleGoogleResponse(res)
          },
        })
        window.google.accounts.id.renderButton(divRef.current, {type : "standard"})
      } catch (error) {
        console.error(error)
      }
    }, [handleGoogleResponse])
  
    return <div ref={divRef} />
  }

  export default GoogleButton