import { GameStats, getSuccessRate, getCurrentStreak } from '../../lib/stats'
import {
  TOTAL_TRIES_TEXT,
  SUCCESS_RATE_TEXT,
  CURRENT_STREAK_TEXT,
  BEST_STREAK_TEXT,
} from '../../constants/strings'

type Props = {
  gameStats: GameStats
}

const StatItem = ({
  stat,
  count,
}: {
  stat: string
  count: string | number
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4">
      <div className="text-3xl text-black dark:text-white font-bold">{count}</div>
      <div className="text-xs">{stat}</div>
    </div>
  )
}

export const StatBar = ({ gameStats }: Props) => {
  return (
    <div className="flex justify-center my-2">
      <StatItem stat={TOTAL_TRIES_TEXT} count={gameStats.gamesPlayed} />
      <StatItem stat={SUCCESS_RATE_TEXT} count={getSuccessRate(gameStats)} />
      <StatItem stat={CURRENT_STREAK_TEXT} count={getCurrentStreak(gameStats)} />
      <StatItem stat={BEST_STREAK_TEXT} count={gameStats.bestStreak} />
    </div>
  )
}
