import { useContext, useEffect, useState } from "react"
import { Animated } from "react-animated-css"
import classNames from 'classnames'
import { useAppStore } from '../../context/AppStoreContext'
import PuzzleContext from '../../context/PuzzleContext.js'
import MiniHeader from '../misc/MiniHeader'
import { SHOW_TRIVIA_TEXT, TRIVIA_COUNTDOWN_TEXT } from '../../constants/strings'
import { END_GAME_STATS_DELAY_TIME_MS } from '../../constants/settings'

type Props = {
    showFullBoard: boolean
    setIsStatsModalOpen: (value: boolean) => void
}

const TriviaAnswer = ({ showFullBoard, setIsStatsModalOpen }: Props) => {
    const store = useAppStore()
    const puzzle = useContext(PuzzleContext)
    const trivia : string = puzzle.Trivia
    const [triviaRevealTimer, setTriviaRevealTimer] = useState(10) 
    const [timerPaused, setTimerPaused] = useState(false)   // State used to check timer paused or not
    const [statsDisplayTimer] = useState(6)                 // Timer for stats display delay
  
    const hasRevealedTrivia = store.triviaRevealed || store.immediateTriviaReveal

    const classes = 'flex justify-center items-center mb-2 mx-auto text-base w-full px-3 py-[4px] leading-5 align-text-top whitespace-pre-line no-text-select bg-unknown text-black dark:bg-unknown-dark dark:text-white'
    const containerClasses = classNames(
        'flex flex-col min-w-[300px] max-w-[600px] iPhonePro:scale-110 smish:scale-125 sm:scale-150 items-center text-center',
        {
            'mt-6 iPhonePro:mt-2 iPhonePro:mb-[19px]' : showFullBoard,
            'mt-7 iPhonePro:mt-[35px]' : !showFullBoard
        }
    )

    const pauseResumeCountdown = () => {
        // If timer is being resumed and more than one second remaining,
        // tick it down so it doesn't stay the same for another second
        if (timerPaused && triviaRevealTimer > 1)
            setTriviaRevealTimer((prev) => prev - 1)

        setTimerPaused((prev) => !prev)    // Toggle timer running
    }

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>

        // If trivia is already revealed, we're done
        if (hasRevealedTrivia) {
            setTriviaRevealTimer(0)
            store.triviaRevealed = true
        // Else set a timer for a second and tick it down
        } else if (triviaRevealTimer > 0 && !timerPaused) {
            timeout = setTimeout(() => {
                setTriviaRevealTimer((prev) => prev - 1)
            }, 1000)
        } else if (triviaRevealTimer <= 0) {
            // Timer expired: reveal trivia
            store.triviaRevealed = true
        }
    
        return () => {
          //cleanup function so it does not run the timeout multiple times
          clearTimeout(timeout)
        }
    }, [triviaRevealTimer, timerPaused, store, hasRevealedTrivia])

    useEffect(() => {
        let statsDisplayTimer: ReturnType<typeof setTimeout>

        if (store.triviaRevealed && !store.statsShareDisplayed) {
            statsDisplayTimer = setTimeout(() => {
                setIsStatsModalOpen(true)
                store.statsShareDisplayed = true
            }, END_GAME_STATS_DELAY_TIME_MS)
        }

        return () => clearTimeout(statsDisplayTimer)
      }, [store, store.triviaRevealed, store.statsShareDisplayed, statsDisplayTimer, setIsStatsModalOpen])

    return (
        <div>
            <div className={containerClasses}>
                <MiniHeader title='Trivia' classes={classNames(
                    'text-sm line-cell',
                    {
                        'mt-[7px]' : !showFullBoard && hasRevealedTrivia,
                        'mt-[14px]' : !showFullBoard && !hasRevealedTrivia
                    })} />
                {!hasRevealedTrivia &&
                <>
                    <div className="text-black dark:text-white">
                        {TRIVIA_COUNTDOWN_TEXT}<br />
                        <Animated key={triviaRevealTimer} animationIn='zoomIn' animateOnMount animationOut='zoomOut' animationInDelay={0} isVisible={true} className="w-full text-4xl font-bold">
                            {triviaRevealTimer}
                        </Animated>
                    </div>
                    <Animated animationIn='zoomIn' animateOnMount animationOut='fadeIn' animationInDelay={0} isVisible={true} className="w-full">
                        <div className="flex items-center gap-5 w-full mt-3">
                            <button className="btn no-text-select w-full" type="button" onClick={() => {pauseResumeCountdown()}}>
                                {timerPaused ? "Resume" : "Pause"}
                            </button>
                            <button className="btn no-text-select w-full" type="button" onClick={() => {store.triviaRevealed = true; setTriviaRevealTimer(-1)}}>
                                {SHOW_TRIVIA_TEXT}
                            </button>
                        </div>
                    </Animated>
                </>
                }
                {hasRevealedTrivia &&
                    <Animated animationIn='zoomIn' animateOnMount animationOut='fadeIn' animationInDelay={0} isVisible={true} className="w-full">
                        <div className='flex flex-col items-center mx-1 mt-1'>
                            <div className='flex justify-center items-center text-center text-white dark:text-slate-300 max-w-10 mb-2 h-[40px] align-text-top'>
                                <div className={classes}>
                                    {trivia}
                                </div>
                            </div>
                        </div>
                    </Animated>
                }    
            </div>
        </div>
    )}

    export default TriviaAnswer
