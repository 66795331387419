import BaseModal from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const classes = "text-sm text-gray-500 dark:text-gray-400 mt-0 mb-2 text-left no-text-select"
const questionClasses = "text-left leading-5 dark:text-white no-text-select"

export const FaqModal = ({ isOpen, handleClose }: Props) => {
    return (
        <BaseModal title="Frequently Asked Questions" isOpen={isOpen} handleClose={handleClose}>
            <>
                <div className={questionClasses}>
                    What is Wumber&reg;?
                </div>
                <p className={classes}>
                    Wumber is a contraction of <i><strong>W</strong></i>inning N<i><strong>umber</strong></i>.
                </p>
                <p className={classes}>
                    A Wumber is a number, up to six digits, and associated trivia.
                    The game is a mash-up of Guess-My-Number, Wordle and a trivia sweepstakes with cash prizes.<sup>*</sup>
                </p>

                <div className={questionClasses}>
                    How do I play?
                </div>
                <p className={classes}>
                    Each day, a Wumber is randomly selected from a pool of community-created Wumbers.
                </p>
                <p className={classes}>
                    You have five chances to correctly guess the Wumber.  You are given a trivia topic and a skill-stop to set your initial guess.
                    After stopping on your first numeric guess, your remaining guesses are by keypad entry.  After each guess, you'll be given 3 hints:
                    <ul style={{ listStyle: "square", marginLeft: "30px" }}>
                        <li>A trivia hint</li>
                        <li>Guess higher/lower next time</li>
                        <li>Which digits are correct and/or present</li>
                    </ul>
                </p>

                <div className={questionClasses}>
                    Do I need an Internet connection to play?
                </div>
                <p className={classes}>
                    Yes. Wumber is a community-sourced living game without pre-determined puzzles. Each day, the game needs to fetch the daily Wumber from the cloud.
                </p>

                <div className={questionClasses}>
                    How long do I get to keep my Wumber?<sup>*</sup>
                </div>
                <p className={classes}>
                    A Wumber you've created is yours forever, so long as you correctly guess a Wumber at least once every 7 days.
                    You may be able to reclaim a Wumber which you've lost by guessing today's Wumber and then resubmitting it under <i>Create Your Wumber</i>.&nbsp;
                    If someone else has created a Wumber with the same underlying number since you lost yours, you'll need to create a new Wumber.
                </p>

                <div className={questionClasses}>
                    Can I change my Wumber if I think of a better one?<sup>*</sup>
                </div>
                <p className={classes}>
                    You can change your Wumber at any time. First release your old Wumber and then submit a new one.
                </p>
                
                <p className="mt-2 leading-4">
                    <sup>* - Coming Soon. NO PURCHASE NECESSARY.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Alternate method of entry available.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Full sweepstakes details will be posted upon launch.</sup>
                </p>
            </>
        </BaseModal>
    )
}
