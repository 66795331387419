import { observer } from 'mobx-react-lite'
import { MAX_GUESSES } from '../../constants/settings'
import { LOSS_ICON } from '../../constants/strings'
import { useAppStore } from '../../context/AppStoreContext'
import Progress from './Progress'

const Histogram = ({ hasLost }: { hasLost: boolean }) => {
    const store = useAppStore()
    const stats = store.stats
    const winDistribution = stats.winDistribution
    const totalWins = winDistribution.reduce((partialSum, a) => partialSum + a, 0)
    const totalLosses = stats.gamesPlayed - totalWins
    const maxValue = Math.max(...winDistribution, totalLosses)
    const numberOfGuessesMade = hasLost ? MAX_GUESSES + 1 : store.guesses.length

    return (
        <div className="rows-1 justify-bottom m-2 text-sm text-black dark:text-white">
            {winDistribution.map((value, i) => (
                <Progress
                    key={i}
                    index={String(i+1)}
                    currentDayStatRow={numberOfGuessesMade === i + 1}
                    size={105 * (value / maxValue)}
                    label={String(value)}
                />
            ))}
            <Progress
                key='X'
                index={LOSS_ICON}
                currentDayStatRow={ numberOfGuessesMade === MAX_GUESSES + 1 }
                size={105 * (totalLosses / maxValue)}
                label={String(totalLosses)}
            />
        </div>
    )
}

export default observer(Histogram)
